import { ApiCall_GET, ApiCall_POST } from "..";

export const getAllCarcomponents = async (_token) => {
  const response = await ApiCall_GET("/api/getallcarcomponents", _token);
  return response;
}

export const getByRepairshopIdCarcomponents = async (_token, _repairshopId) => {
  const response = await ApiCall_GET(`/api/getbyrepairshopidcarcomponents?id_repairshop=${_repairshopId}`, _token);
  return response;
}

export const getByRepairshopIdCustomCarcomponents = async (_token, _repairshopId) => {
  const response = await ApiCall_GET(`/api/getbyrepairshopidcustomcarcomponents?id_repairshop=${_repairshopId}`, _token);
  return response;
}

export const postCustomCarcomponents = async (_token, _data) => {
  const response = await ApiCall_POST("/api/postcustomcarcomponents", _data, _token);
  return response;
}