import { ApiCall_GET, ApiCall_PATCH } from "..";

export const getByUserIdNotifications = async (_token, userId) => {
  const response = await ApiCall_GET(`/api/getByUserIdNotifications?id_user_main=${userId}`, _token);
  return response;

}

export const updateNotification = async (_token, _id, _field) => {
  const response = await ApiCall_PATCH(`/api/updatenotification?id=${_id}&field=${_field}`, { null: null }, _token);
  return response;
}