import {
  Stack,
  Typography,
  Container,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PieceComponent = ({ part }) => {
  const [pieceExpandedState, setPieceExpandedState] = React.useState(false);

  const handleExpand = (panel) => (event, isExpanded) => {
    setPieceExpandedState(isExpanded);
  };

  const renderStatus = (part) => {
    if (part.is_replaced) {
      return (
        <Container
          sx={{
            fontSize: "0.8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            width: "90px",
            color: "white",
            backgroundColor: "#4abe48",
          }}
        >
          Reemplazado
        </Container>
      );
    }
    if (part.status === 0) {
      return (
        <Container
          sx={{
            fontSize: "0.8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            width: "80px",
            color: "green",
            backgroundColor: "#ccffda",
          }}
        >
          Óptimo
        </Container>
      );
    } else if (part.status === 1) {
      return (
        <Container
          sx={{
            fontSize: "0.8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            width: "80px",
            color: "#786a00",
            backgroundColor: "#fffccc",
          }}
        >
          Regular
        </Container>
      );
    } else if (part.status === 2) {
      return (
        <Container
          sx={{
            fontSize: "0.8rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            width: "80px",
            color: "red",
            backgroundColor: "#ffcccc",
          }}
        >
          Deficiente
        </Container>
      );
    }
  };

  return (
    <Stack
      direction={"row"}
      backgroundColor={"#f5f5f5"}
      justifyContent={"space-between"}
      sx={{ width: "calc(100% - 20px)", padding: "10px", borderRadius: "0px" }}
    >
      <Typography
        sx={{
          fontWeight: "300",
          fontSize: "0.8rem",
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {part.name}
      </Typography>
      <Typography sx={{ fontWeight: "300" }}>{renderStatus(part)}</Typography>
    </Stack>
  );
};
const InterventionCard = ({ intervention, index }) => {
  const [pieceExpandedState, setPieceExpandedState] = React.useState(
    index === 0 ? true : false,
  );

  const handleExpand = (panel) => (event, isExpanded) => {
    setPieceExpandedState(isExpanded);
  };

  return (
    <Accordion
      sx={{ width: "100%", boxShadow: "0", maxWidth: "1000px" }}
      onChange={handleExpand("piece")}
      expanded={pieceExpandedState}
    >
      <AccordionSummary
        sx={{ width: "100%" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Stack gap={"10px"} direction={"row"} justifyContent={"space-between"}>
          <Typography sx={{ fontWeight: "600" }}>
            {new Date(intervention.created_at).toLocaleDateString()}
          </Typography>
          <Typography sx={{ fontWeight: "300" }}>
            {intervention.km?.toLocaleString()} kms
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          sx={{
            width: "calc(100%-20)",
            height: "fit-content",
            padding: "10px",
          }}
        >
          {intervention.comments && (
            <Typography
              sx={{
                backgroundColor: "#f5f5f5",
                color: "primary.main",
                textAlign: "center",
                padding: "10px",
                borderRadius: "0px",
              }}
            >
              {intervention.comments}
            </Typography>
          )}
          <Typography
            sx={{
              fontWeight: "300",
              fontSize: "1.1rem",
              textWrap: "nowrap",
              margin: "10px 0",
            }}
          >
            {" "}
            {intervention.carcomponents.filter((part) => part.is_replaced)
              .length > 0 && "Piezas cambiadas"}{" "}
          </Typography>

          <Stack
            direction={"row"}
            gap={"5px"}
            flexWrap={"wrap"}
            justifyContent={"center"}
          >
            {intervention.carcomponents.map((part) => {
              if (part.is_replaced)
                return (
                  <Typography
                    sx={{
                      fontWeight: "300",
                      fontSize: "0.8rem",
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      backgroundColor={"#f5f5f5"}
                      justifyContent={"center"}
                      sx={{
                        maxWidth: "100px",
                        padding: "10px",
                        borderRadius: "0px",
                        minWidth: "200px",
                      }}
                    >
                      {`${part.name}`}
                    </Stack>
                  </Typography>
                );
            })}
          </Stack>
          {intervention.carcomponents.length > 0 && (
            <Typography
              sx={{
                fontWeight: "300",
                fontSize: "1.1rem",
                textWrap: "nowrap",
                margin: "10px 0",
              }}
            >
              Revisiones
            </Typography>
          )}
          <Stack
            direction={"row"}
            gap={"5px"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
          >
            {intervention.carcomponents.map((part) => {
              if (!part.is_replaced) return <PieceComponent part={part} />;
            })}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
const InterventionComponent = ({ vehicle }) => {
  return (
    <Stack
      gap={"20px"}
      sx={{
        width: "calc(100%-30px)",
        padding: "15px",
        height: "fit-content",
        justifyContent: "center",
      }}
    >
      <Stack
        overflow={"hidden"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        width={"100%"}
        boxShadow={"0 0 10px 0 rgba(0, 0, 0, 0.15)"}
        gap={"10px"}
        flexWrap={"wrap"}
      >
        <Stack
          width={"100%"}
          height={"40px"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            marginTop={"10px"}
            color={"grey"}
            sx={{ textTransform: "uppercase", fontWeight: "200" }}
          >
            Intervenciones
          </Typography>
        </Stack>
        {vehicle && vehicle?.interventions.length === 0 ? (
          <Typography>Todavía no hay intervenciones</Typography>
        ) : (
          vehicle?.interventions
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((intervention, index) => (
              <InterventionCard index={index} intervention={intervention} />
            ))
        )}
      </Stack>
    </Stack>
  );
};

export default InterventionComponent;
