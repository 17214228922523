import { Stack, styled } from "@mui/material";
import React from "react";
import NotificationCenter from "../notificationCenter/notificationCenter.Component.jsx";
import UserProfilePhotoComponent from "../userProfilePhoto/userProfilePhoto.Component.jsx";
import { useLocation } from "react-router-dom";

const TopNavbar = () => {
  const location = useLocation();

  const MainContainer = styled(Stack)(() => ({
    position: "fixed",
    zIndex: "1",
    padding: "10px 25px",
    height: "30px",
    width: "calc(100% - 350px)",
    display: "flex",
    direction: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "5px",
    marginLeft: "300px",
  }));

  return (
    <>
      {location.pathname === "/intervencion" || location.pathname === "/leads" ? null : (
        <MainContainer direction={"row"}>
          <NotificationCenter />
          <UserProfilePhotoComponent />
        </MainContainer>
      )}
    </>
  );
};

export default TopNavbar;
