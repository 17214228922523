import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStorageObject } from "../../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const UserProfilePhotoComponent = () => {
  const [imageState, setImageState] = useState(false);
  const size = "30px";

  useEffect(() => {
    const userData = getStorageObject("userData");

    const userId = userData?.id_user_repairshop;
    console.log("USERIDDDuserId");
    const imageUrl = `https://storage.googleapis.com/drivimobility-public-storage/profile_pictures/${userId}?timestamp=${Date.now()}`;
    setImageState(imageUrl);
  }, []);

  const handleError = () => {
    setImageState("/img/defaultProfilePicture.svg");
  };

  const navigate = useNavigate();

  const MainContainer = styled(Stack)(({ theme }) => ({
    position: "relative",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      cursor: "pointer",
    },
  }));

  return (
    <MainContainer
      id="id-user-profile-photo"
      height={size}
      width={size}
      onClick={() => navigate("/taller")}
    >
      <img
        onError={handleError}
        style={{
          transition: "0.3s",
          borderRadius: "50%",
          height: "100%",
          width: "100%",
          objectFit: "contain",
        }}
        src={imageState}
        alt="logo"
      />
    </MainContainer>
  );
};

export default UserProfilePhotoComponent;
