import React, {useEffect, useState} from "react";
import {Button, Stack, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import MainContent from "../../components/MainContent.component";
import LoadingComponent from "../../components/Loading.Component";
import HealthComponent from "./components/Health.Component";
import InfoComponent from "./components/Info.Component";
import InterventionComponent from "./components/Intervention.Component";
import {createManualIntervention} from "../../services/API/Intervention.Services";
import {getStorageObject} from "../../utils/LocalStorage";
import vehicleData from "../../utils/demoData/vehicleData.json";
import {demoAtom, userNameAtom, vehicleNameAtom} from "../../context/atoms/Atoms.Storage";
import {useAtom} from "jotai";
import {getAllVehicleData} from "../../services/API/Vehicle.Services";
import BackButton from "../../components/backButton/BackButton.Component";
import ConfirmModalComponent from "../../components/confirmModal/ConfirmModal.Component";
import TechnicalSheetUpload from "./components/TechnicalSheetUpload";
import ModifyVehModal from "./components/ModifyVeh.Modal";

const VehiclePage = () => {
    //HOOKS

    const navigate = useNavigate();

    //STATES

    const [vehicleState, setVehicleState] = useState();
    const [loadingState, setLoadingState] = useState(true);
    const [flexDirectionState, setFlexDirectionState] = useState("row");
    const [demo, setDemo] = useAtom(demoAtom);
    const [vehicleName, setVehicleName] = useAtom(vehicleNameAtom);
    const [userClientName, setUserClientName] = useAtom(userNameAtom);
    const [confirmModalIsOpenState, setConfirmModalIsOpenState] = useState(false);
    const [userId, setUserId] = useState();

    const params = useParams();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1130) {
                setFlexDirectionState("column");
            } else {
                setFlexDirectionState("row");
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    //USE EFFECT

    useEffect(() => {
        const demoData = getStorageObject("userData")?.id_user_profile;

        if (demoData === 3) {
            const vehicleFromData = vehicleData.find((vehicle) => vehicle.vehicle.id === Number(params.vehicleId));

            setVehicleState(vehicleFromData);
            setLoadingState(false);

            return;
        } else {
            const token = getStorageObject("token");


            getAllVehicleData(token, params.vehicleId)
                .then(({data: vehicleData, status, ok}) => {
                    if (ok) {
                        console.log(vehicleData);
                        setVehicleState(vehicleData);
                        setUserId(vehicleData.id_user_owner);
                    }
                    setLoadingState(false);
                })
                .catch((error) => {
                    setVehicleState(null);
                    setLoadingState(false);
                });
        }
    }, []);

    //FUNCTIONS

    const createIntervention = async () => {
        const interventionData = {
            user: null,
            vehicle: {
                id: params.vehicleId,
            },
        };

        const token = getStorageObject("token");

        const {data: interventionResponse} = await createManualIntervention(token, interventionData);

        const InterventionURL = `/intervencion?id2=${interventionResponse.id}&id4=${token}`;

        window.open(InterventionURL, "_blank");
    };

    //RENDER

    if (loadingState) {
        return <LoadingComponent/>;
    }

    return (
        <MainContent>
            {vehicleState ? (
                <>
                    <Stack sx={{position: "absolute", top: "10px", left: "10px", zIndex: "100"}}
                           justifyContent={"center"} gap={0} direction={flexDirectionState}>
                        <BackButton route={-1}/>
                    </Stack>

                    <Stack marginTop={"35px"} gap={0} direction={flexDirectionState}>
                        <Stack width={flexDirectionState === "column" ? "100%" : "33%"}>
                            {vehicleState && <InfoComponent userData={vehicleState.user || vehicleState.user_owner}
                                                            setVehicle={setVehicleState}
                                                            vehicle={vehicleState.vehicle}/>}
                            {vehicleState && <InterventionComponent vehicle={vehicleState}/>}
                        </Stack>
                        {vehicleState && <HealthComponent vehicle={vehicleState}/>}
                    </Stack>
                </>
            ) : (
                <Stack sx={{justifyContent: "center", alignItems: "center", maxWidth: "40em", margin: "auto"}}>
                    <BackButton route={-1}/>
                    <Typography sx={{textAlign: "center", fontWeight: "100"}} variant="h2">
                        No hay intervenciones para el <span
                        style={{textTransform: "capitalize"}}> {vehicleName} </span> de {userClientName}
                    </Typography>
                    <Typography sx={{
                        fontSize: "1.5rem",
                        textAlign: "center",
                        fontWeight: "200",
                        padding: "1em 0 0.5em 0",
                        color: "#6f6f6f"
                    }}>De momento.</Typography>
                    <Button
                        sx={{marginTop: "20px"}}
                        variant="contained"
                        onClick={() => {
                            setConfirmModalIsOpenState(true);
                        }}
                    >
                        Crear intervención
                    </Button>
                </Stack>
            )}
            {confirmModalIsOpenState && (
                <ConfirmModalComponent
                    confirmText="¿Deseas crear la intervención?"
                    handleConfirm={createIntervention}
                    handleCancel={() => {
                        setConfirmModalIsOpenState(false);
                    }}
                />
            )}
        </MainContent>
    );
};

export default VehiclePage;
