import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/Modal.Component.jsx";
import {
  Autocomplete,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  plateValidator,
  kmsValidator,
  yearValidator,
} from "../../../utils/Validators.js";
import { getStorageObject } from "../../../utils/LocalStorage.js";
import { getAllManufacturers } from "../../../services/API/Manufacturer.Services.js";
import { getAllModels } from "../../../services/API/Model.Services.js";
import LoadingComponent from "../../../components/Loading.Component.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { updateVehicleData } from "../../../services/API/Vehicle.Services.js";
import { useToast } from "../../../components/toastComponent/toast.Actions.jsx";
import { CircularProgress } from "@mui/material";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import "./OrangeDatePicker.css";

const ModifyVehModal = ({ vehicle, setEditMode, setVehicle }) => {
  const { openCustomToast } = useToast();

  const formatKm = (km) => {
    if (km) return new Intl.NumberFormat("es-ES").format(km);
    else return "";
  };

  const [energy, setEnergy] = useState(vehicle.id_vehicle_energy || null);
  const [manufacturersState, setManufacturersState] = useState([]);
  const [modelsState, setModelsState] = useState([]);
  const [autoCompleteValueState, setAutoCompleteValueState] = useState(null);
  const [modelAutocompleteValueState, setModelAutocompleteValueState] =
    useState(null);
  const [licensePlateState, setLicensePlateState] = useState(
    vehicle.plate || null,
  );
  const [kmsState, setKmsState] = useState(
    vehicle.km ? formatKm(vehicle.km) : null,
  );
  const [yearState, setYearState] = useState(vehicle.year || null);
  const [versionState, setVersionState] = useState(vehicle.version || null);
  const [nextItv, setNextItv] = useState(
    vehicle.date_nextitv ? new Date(vehicle.date_nextitv) : null,
  );
  const [lastItv, setLastItv] = useState(
    vehicle.date_lastitv ? new Date(vehicle.date_lastitv) : null,
  );
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const handleKmChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    const formattedValue = new Intl.NumberFormat("es-ES").format(value);

    setKmsState(formattedValue);
  };

  const weekDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const handleSubmit = async () => {
    setSaveLoading(true);

    let vehicleUpdateData = {
      id: vehicle.id,
    };

    if (
      autoCompleteValueState &&
      autoCompleteValueState.id !== vehicle?.vehicle_manufacturer?.id
    ) {
      vehicleUpdateData.id_vehicle_manufacturer = autoCompleteValueState.id;
      vehicleUpdateData.manufacturer_name = autoCompleteValueState.label;
    }
    if (
      modelAutocompleteValueState &&
      modelAutocompleteValueState.id !== vehicle?.vehicle_model?.id
    ) {
      vehicleUpdateData.id_vehicle_model = modelAutocompleteValueState.id;
      vehicleUpdateData.model_name = modelAutocompleteValueState.label;
    }
    if (versionState && versionState !== vehicle.version)
      vehicleUpdateData.version = versionState;
    if (kmsState && kmsState.replace(".", "") !== vehicle.km)
      vehicleUpdateData.km = parseInt(kmsState.replaceAll(".", ""));
    if (yearState && yearState !== vehicle.year)
      vehicleUpdateData.year = yearState;
    if (licensePlateState && licensePlateState !== vehicle.plate)
      vehicleUpdateData.plate = licensePlateState;
    if (energy && energy !== vehicle.id_vehicle_energy) {
      vehicleUpdateData.id_vehicle_energy = energy;
      vehicleUpdateData.energy_name = energy === 1 ? "Gasolina" : "Diésel";
    }
    if (nextItv && nextItv !== vehicle.date_nextitv)
      vehicleUpdateData.date_nextitv = nextItv;
    if (lastItv && lastItv !== vehicle.date_lastitv)
      vehicleUpdateData.date_lastitv = lastItv;

    const token = getStorageObject("token");

    const response = await updateVehicleData(token, vehicleUpdateData);

    if (response.ok) {
      setVehicle((prevVehicle) => ({
        ...prevVehicle,
        vehicle: {
          ...prevVehicle.vehicle,
          id: vehicleUpdateData.id || prevVehicle.vehicle.id,
          vehicle_manufacturer: {
            id:
              vehicleUpdateData.id_vehicle_manufacturer ||
              prevVehicle.vehicle.vehicle_manufacturer.id,
            name:
              vehicleUpdateData.manufacturer_name ||
              prevVehicle.vehicle.vehicle_manufacturer.name,
          },
          vehicle_model: {
            id:
              vehicleUpdateData.id_vehicle_model ||
              prevVehicle.vehicle.vehicle_model.id,
            name:
              vehicleUpdateData.model_name ||
              prevVehicle.vehicle.vehicle_model.name,
          },
          version: vehicleUpdateData.version || prevVehicle.vehicle.version,
          km: vehicleUpdateData.km || prevVehicle.vehicle.km,
          year: vehicleUpdateData.year || prevVehicle.vehicle.year,
          plate: vehicleUpdateData.plate || prevVehicle.vehicle.plate,
          id_vehicle_energy:
            vehicleUpdateData.id_vehicle_energy ||
            prevVehicle.vehicle.id_vehicle_energy,
          energy_name:
            vehicleUpdateData.energy_name || prevVehicle.vehicle.energy_name,
          date_nextitv:
            vehicleUpdateData.date_nextitv || prevVehicle.vehicle.date_nextitv,
          date_lastitv:
            vehicleUpdateData.date_lastitv || prevVehicle.vehicle.date_lastitv,
        },
      }));
    }

    setSaveLoading(false);
    openCustomToast("Datos guardados correctamente", "success");

    setEditMode(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getStorageObject("token");

      try {
        const manufacturers = await getAllManufacturers(token);
        const models = await getAllModels(token);

        const transformedModels = models.data.map((model) => ({
          id: model.id,
          value: model.id,
          label: model.name,
          manufacturer: model.id_vehicle_manufacturer,
        }));
        setModelsState(transformedModels);

        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const transformedManufacturers = manufacturers.data.map(
          (manufacturer) => ({
            id: manufacturer.id,
            value: manufacturer.id,
            label: capitalize(manufacturer.name),
          }),
        );
        setManufacturersState(transformedManufacturers);
        setAutoCompleteValueState(
          transformedManufacturers.find(
            (manufacturer) =>
              manufacturer.value === vehicle.vehicle_manufacturer?.id,
          ),
        );
        setModelAutocompleteValueState(
          transformedModels.find(
            (model) => model.value === vehicle.vehicle_model?.id,
          ),
        );

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <ModalComponent>
      <Stack
        sx={{
          paddingTop: "40px",
          position: "relative",
          alignItems: "center",
          backgroundColor: "white",
          padding: "20px",
          gap: "15px",
          maxHeight: "calc(100vh - 100px)",
          overflowY: "auto",
          minWidth: "300px",
        }}
      >
        <IconButton
          onClick={() => setEditMode(false)}
          sx={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            padding: "10px",
            textTransform: "uppercase",
            fontWeight: "300",
          }}
        >
          Introduce los datos
        </Typography>
        <Autocomplete
          disablePortal
          disabled={saveLoading}
          value={autoCompleteValueState}
          onChange={(event, newValue) => {
            setAutoCompleteValueState(newValue);
            setModelAutocompleteValueState(null);
          }}
          options={manufacturersState}
          sx={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            fontSize: "2em",
            fontWeight: "bolder",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Marca"
              variant="standard"
              error={Boolean(errors.manufacturer)}
              helperText={errors.manufacturer}
            />
          )}
        />
        {autoCompleteValueState && (
          <Autocomplete
            disabled={saveLoading}
            disablePortal
            value={modelAutocompleteValueState}
            onChange={(event, newValue) => {
              setModelAutocompleteValueState(newValue);
            }}
            options={modelsState.filter(
              (model) => model.manufacturer === autoCompleteValueState.id,
            )}
            sx={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              fontSize: "2em",
              fontWeight: "bolder",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Modelo"
                variant="standard"
                error={Boolean(errors.model)}
                helperText={errors.model}
              />
            )}
          />
        )}
        <TextField
          disabled={saveLoading}
          sx={{ width: "100%" }}
          variant="standard"
          label="Versión"
          value={versionState}
          onChange={(e) => setVersionState(e.target.value)}
        />
        <TextField
          disabled={saveLoading}
          sx={{ width: "100%" }}
          onChange={handleKmChange}
          variant="standard"
          type="text"
          label="Kilometraje"
          value={kmsState}
          error={Boolean(errors.kms)}
          helperText={errors.kms}
          InputProps={{
            endAdornment: kmsState && (
              <InputAdornment position="end">kms</InputAdornment>
            ),
          }}
        />

        <TextField
          disabled={saveLoading}
          sx={{ width: "100%" }}
          onChange={(e) => setYearState(e.target.value)}
          variant="standard"
          type="number"
          label="Año"
          value={yearState}
          error={Boolean(errors.year)}
          helperText={errors.year}
        />
        <FormControl
          disabled={saveLoading}
          sx={{ width: "100%", display: "flex" }}
          error={Boolean(errors.energy)}
        >
          <InputLabel sx={{ left: "-13px" }} id="energy-label">
            Combustible
          </InputLabel>
          <Select
            MenuProps={{ disablePortal: true }}
            sx={{ display: "flex" }}
            variant="standard"
            labelId="energy-label"
            id="energy"
            value={energy}
            onChange={(e) => setEnergy(e.target.value)}
          >
            <MenuItem value={1}>Diésel</MenuItem>
            <MenuItem value={2}>Gasolina</MenuItem>
            <MenuItem value={3}>Híbrido</MenuItem>
            <MenuItem value={4}>Eléctrico</MenuItem>
            <MenuItem value={5}>GLP</MenuItem>
          </Select>
          {errors.energy && (
            <Typography variant="caption" color="error">
              {errors.energy}
            </Typography>
          )}
        </FormControl>
        <DatePicker
          value={nextItv}
          onChange={setNextItv}
          weekDays={weekDays}
          months={months}
          zIndex={3000}
          calendarPosition={"top"}
          format="M/YYYY"
          onlyMonthPicker
          style={{ width: "100%" }}
          animations={[
            opacity(),
            transition({
              from: 40,
              transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
            }),
          ]}
          render={
            <TextField
              variant="standard"
              inputProps={{ readOnly: true }}
              label="Próxima ITV"
              sx={{ width: "100%" }}
            />
          }
          className="orange derecha"
        />
        <DatePicker
          value={lastItv}
          onChange={setLastItv}
          weekDays={weekDays}
          months={months}
          zIndex={3000}
          calendarPosition={"top"}
          format="M/YYYY"
          onlyMonthPicker
          style={{ width: "100%" }}
          animations={[
            opacity(),
            transition({
              from: 40,
              transition: "all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)",
            }),
          ]}
          render={
            <TextField
              variant="standard"
              inputProps={{ readOnly: true }}
              label="Última ITV"
              sx={{ width: "100%" }}
            />
          }
          className="orange derecha"
        />
        <TextField
          disabled={saveLoading}
          required
          sx={{ width: "100%" }}
          variant="standard"
          value={licensePlateState}
          onChange={(e) => setLicensePlateState(e.target.value)}
          label="Matrícula"
          error={Boolean(errors.licensePlate)}
          helperText={errors.licensePlate}
        />
        <Button
          disabled={saveLoading}
          onClick={handleSubmit}
          sx={{ marginTop: "20px", width: "100%" }}
          type="submit"
          variant="contained"
        >
          {saveLoading ? <CircularProgress size={24} /> : "Guardar cambios"}
        </Button>
      </Stack>
    </ModalComponent>
  );
};

export default ModifyVehModal;
