import { ApiCall_GET, ApiCall_POST } from "..";

export const getAllModels = async (_token) => {
    const response = await ApiCall_GET("/api/getallmodels", _token);
    return response;
  }
  
export const postModel = async (_token, _body) => {
    const response = await ApiCall_POST("/api/postmodel", _body, _token);
    return response;
  }
