import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/Modal.Component";
import { getAllManufacturers } from "../../../services/API/Manufacturer.Services";
import { getAllModels, postModel } from "../../../services/API/Model.Services";
import { getStorageObject } from "../../../utils/LocalStorage";
import { Autocomplete, Button, Grid, IconButton, Stack, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmModalComponent from "../../../components/confirmModal/ConfirmModal.Component";
import { useToast } from "../../../components/toastComponent/toast.Actions";
import LoadingComponent from "../../../components/Loading.Component";

const ModelComponent = ({ open, setOpen }) => {
  const { openCustomToast } = useToast();

  const [manufacturersListState, setManufacturersListState] = useState();
  const [modelsState, setModelsState] = useState();
  const [modelState, setModelState] = useState();
  const [manufacturerState, setManufacturerState] = useState();
  const [loadingState, setLoadingState] = useState();
  const [modelInputValue, setModelInputValue] = useState();
  const [confirmModalIsOpenState, setConfirmModalIsOpenState] = useState(false);
  const [test, setTest] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const handleChange_AutocompleteField = (event, value) => {
    setManufacturerState(value);
    setModelState(null);
  };
  const handleChange_Model_AutocompleteField = (event, value) => {
    setModelState(value);
  };
  const handleChange_Model_InputField = (event, value) => {
    setModelInputValue(value);
  };

  useEffect(() => {
    if (!manufacturerState || !manufacturersListState || !modelInputValue) {
      setDisableButton(true);
      return;
    }

    const filteredModels = modelsState.filter((model) => model?.manufacturer === manufacturerState?.id);

    const noExactMatches = filteredModels.find((model) => model?.label?.toLowerCase() === modelInputValue?.toLowerCase());

    if (noExactMatches) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [modelInputValue, modelState, manufacturerState, manufacturersListState, modelsState]);

  useEffect(() => {
    const token = getStorageObject("token") || jwtState;

    getAllManufacturers(token).then(({ data: manufacturers }) => {
      getAllModels(token).then(({ data: models }) => {
        const transformedModels = models.map((model) => {
          return {
            id: model.id,
            value: model.id,
            label: model.name,
            manufacturer: model.id_vehicle_manufacturer,
          };
        });
        setModelsState(transformedModels);
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const transformedManufacturers = manufacturers.map((manufacturer) => {
          const value = manufacturer.name;
          return {
            id: manufacturer.id,
            value: manufacturer.id,
            label: capitalize(value),
          };
        });

        setManufacturersListState(transformedManufacturers);
        setLoadingState(false);
      });
    });
    setLoadingState(false);
  }, [test]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setConfirmModalIsOpenState(true);
    setLoadingState(true);

    const token = getStorageObject("token");
    const body = {
      name: modelInputValue,
      id_vehicle_manufacturer: manufacturerState?.id,
    };
    postModel(token, body).then((response) => {
      if (response.ok) {
        openCustomToast("Modelo creado correctamente", "success");
        setLoadingState(false);
        setTest(!test);
        setConfirmModalIsOpenState(false);
      } else {
        openCustomToast("Ha ocurrido un error", "error");
        setLoadingState(false);
      }
    });
  };

  return (
    <ModalComponent>
      <Stack
        sx={{
          position: "relative",
          maxWidth: "400px",
          minWidth: "300px",
          backgroundColor: "white",
          display: "flex",
          height: "fit-content",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          p: "15px",
          paddingTop: "45px",
        }}
      >
  
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          sx={{ position: "absolute", top: "3px", left: "3px", height: "fit-content", width: "fit-content", zIndex: "10" }}
        >
          <CloseIcon />
        </IconButton>
        <Stack width={"80%"}>
          <Autocomplete
            disablePortal
            clearOnBlur
            onChange={handleChange_AutocompleteField}
            options={manufacturersListState}
            xs={6}
            sm={4}
            xl={5}
            sx={{ width: "90%", textAlign: "center", justifyContent: "center", fontSize: "2em", fontWeight: "bolder", border: !manufacturerState && "1px solid red" }}
            value={manufacturerState}
            renderInput={(params) => <TextField {...params} label="Marca" variant="standard" />}
          />
          {manufacturerState && (
            <Autocomplete
              disablePortal
              onChange={handleChange_Model_AutocompleteField}
              onInputChange={handleChange_Model_InputField}
              options={modelsState.filter((model) => model?.manufacturer === manufacturerState?.id)}
              xs={6}
              sm={4}
              xl={5}
              sx={{ width: "90%", textAlign: "center", justifyContent: "center", fontSize: "2em", fontWeight: "bolder", border: !modelState && "1px solid red" }}
              value={modelState}
              inputValue={modelInputValue}
              onKeyDown={() => setTest(!test)}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Modelo" variant="standard" />}
            />
          )}
        </Stack>
        <Button variant="contained" onClick={() => setConfirmModalIsOpenState(true)} disabled={disableButton}>
          Añadir Modelo
        </Button>
        {confirmModalIsOpenState && (
          <ConfirmModalComponent confirmText={"¿Estás seguro de que quieres añadir el modelo " + modelInputValue + "?"} handleConfirm={handleSubmit} handleCancel={() => setConfirmModalIsOpenState(false)} />
        )}
      </Stack>
    </ModalComponent>
  );
};

export default ModelComponent;
