import { IconButton, ListItem, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const PromotionComponent = ({
  workshopState,
  key,
  promo,
  formatDate,
  handlePromoDate,
  deletePromotion,
}) => {
  const [minDateState, setMinDateState] = useState(
    formatDate(new Date(promo?.date_start)),
  );
  const [maxDateState, setMaxDateState] = useState(
    formatDate(new Date(promo?.date_expiration)),
  );

  return (
    <ListItem
      sx={{
        backgroundColor: "#c3c3c3",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      width={"100%"}
      key={key}
      secondaryAction={
        <IconButton
          onClick={() =>
            deletePromotion(workshopState.repairshop_promotions.indexOf(promo))
          }
          edge="end"
          aria-label="delete"
        >
          <DeleteIcon style={{ color: "black" }} />
        </IconButton>
      }
    >
      <Typography
        style={{
          color: "black",
          fontWeight: "300",
          fontFamily: "Inter",
          fontSize: "0.9rem",
        }}
      >
        {promo.name}
      </Typography>
      <Stack sx={{ display: "flex", gap: "10px" }}>
        <Stack sx={{ minWidth: "100px" }} gap={"10px"} direction={"row"}>
          <Typography>Inicio:</Typography>
          <input
            style={{
              border: "none",
              fontFamily: "Inter",
              fontSize: "0.8rem",
              padding: "5px",
            }}
            type="date"
            onChange={(e) => {
              handlePromoDate(e, promo, "start");
              setMinDateState(e.target.value);
            }}
            defaultValue={new Date(promo?.date_start)}
            value={minDateState}
            onKeyDown={(e) => e.preventDefault()}
            max={maxDateState}
          />
        </Stack>
        <Stack
          sx={{ minWidth: "100px", justifyContent: "space-between" }}
          gap={"10px"}
          direction={"row"}
        >
          <Typography>Fin:</Typography>
          <input
            style={{
              border: "none",
              fontFamily: "Inter",
              fontSize: "0.8rem",
              padding: "5px",
            }}
            type="date"
            min={minDateState}
            value={maxDateState}
            onChange={(e) => {
              handlePromoDate(e, promo, "expiration");
              setMaxDateState(e.target.value);
            }}
            onKeyDown={(e) => e.preventDefault()}
            defaultValue={new Date(promo?.date_expiration)}
          />
        </Stack>
      </Stack>
    </ListItem>
  );
};

export default PromotionComponent;
