import React from "react";
import { Container, Stack } from "@mui/material";
import styled from "styled-components";
import MainContent from "./MainContent.component";
import MainContentComponent from "./MainContent.component";

const AnimatedContainer = styled(Container)`
  position: fixed;
  min-width: 100%;
  min-height: 100vh;

  margin: 0 !important;
  padding: 0 !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  animation: fadeIn 0.4s ease-out forwards;
  will-change: opacity, backdrop-filter;
  align-items: center;
  z-index: 1000;
  justify-content: center;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      backdrop-filter: brightness(1);
    }
    100% {
      opacity: 1;
      backdrop-filter: brightness(0.8);
    }
  }
`;

const ModalComponent = ({ children }) => {
  return (
    <AnimatedContainer>
      <MainContentComponent>
        <Stack
          sx={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000000",
          }}
        >
          {children}
        </Stack>
      </MainContentComponent>
    </AnimatedContainer>
  );
};

export default ModalComponent;
