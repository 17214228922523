import { ApiCall_GET, ApiCall_POST, ApiCall_PATCH } from "..";
export const getCalendarData = async (_token, _year, _clientId) => {
    const response = await ApiCall_GET(`/api/getcalendar?year=${_year}&userId=${_clientId}`, _token);
    return response;
}

export const GetAllAppointedBilling = async (_token, _clientId) => {
    const response = await ApiCall_GET(`/api/getallappointedbilling?clientId=${_clientId}`, _token);
    return response;
}

