import React, { useEffect, useState } from "react";
import { getByRepairshopIdCustomCarcomponents } from "../../../services/API/Carcomponent.Services";
import { getStorageObject } from "../../../utils/LocalStorage";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import DataTable from "./DataTable";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { notificationAtom } from "../../../context/atoms/Atoms.Storage";
import { useAtom } from "jotai";
import { updateNotification } from "../../../services/API/Notification.Services";

const CustomCarcomponentsComponent = ({ customCarcomponents }) => {
  const [notificationsState, setNotificationsState] = useAtom(notificationAtom);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (!notificationsState) {
      return;
    }

    const newFeatureNotification = notificationsState.find((notification) => notification.id_notification == 3);

    if (newFeatureNotification?.is_closed == false) {
      setIsTooltipOpen(true);
    } else {
      setIsTooltipOpen(false);
    }
  }, [notificationsState]);

  const handleClose = async () => {
    const newFeatureNotification = notificationsState.find((notification) => notification.id_notification == 3);

    if (!newFeatureNotification) {
      setIsTooltipOpen(false);
      return;
    }

    const userData = getStorageObject("userData");
    const token = getStorageObject("token");

    try {
      await updateNotification(token, newFeatureNotification.id, "is_closed");
    } catch (error) {
      console.error(error);
    }
    setIsTooltipOpen(false);
  };

  return (
    <Tooltip
      zIndex={-3}
      marginBottom={7}
      marginTop={7}
      arrow={true}
      placement={"top"}
      open={isTooltipOpen}
      title={
        <>
          <Typography sx={{ fontSize: "0.8rem" }}>¡Nueva función! Ahora puedes personalizar todos los componentes.</Typography> <Button onClick={handleClose}>Entendido</Button>
        </>
      }
    >
      <Stack sx={{ position: "relative" }}>
        <NewReleasesIcon sx={{ position: "absolute", height: "50px", width: "50px", color: "#e94e1b", top: "-25px", right: "-25px" }} />
        <Stack alignItems={"center"}>
          {customCarcomponents && <DataTable carcomponents={customCarcomponents} />}

          {/* {customCarcomponents &&
        customCarcomponents.map((carcomponent) => (
          <div key={carcomponent.id}>
            {carcomponent.name} {carcomponent.caducidad_meses + carcomponent.caducidad_km}
          </div>
        ))} */}
        </Stack>
      </Stack>
    </Tooltip>
  );
};

export default CustomCarcomponentsComponent;
