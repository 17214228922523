import React from 'react'
import { Stack, Typography } from '@mui/material'
import MainContent from '../../components/MainContent.component'

const NotFoundPage = () => {
    return (
        <MainContent>
            <Stack height="100vh" gap={"30px"} alignItems="center" justifyContent="center">
                <img style={{ height: "50px" }} src="/img/drivi_logo.svg" alt="" />
                <Typography variant="h3" sx={{ fontWeight: "100", color: "#e94e1b" }}>Oops! Esta página no existe</Typography>
            </Stack>
        </MainContent>
    )
}

export default NotFoundPage