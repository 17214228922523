import { ApiCall_POST, ApiCall_PATCH } from "..";



export const setWorkshopUserImpersonation = async (_data, _token) => {
    const response = await ApiCall_POST(`/api/admin/setWorkshopUserImpersonation`, _token, _data);
    return response;
}

export const updateRepairshopSubscription = async (_data, _token) => {
    const response = await ApiCall_PATCH(`/api/admin/updateRepairshopSubscription`, _token, _data);
    return response;
}
