import { CircularProgress, Stack, Typography } from "@mui/material";
import { MotionConfig } from "framer-motion";
import React from "react";
import { motion } from "framer-motion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ConfirmedComponent = () => {
  const logoStyle = {
    width: "200px",
    animation: "swing3D 4s infinite ease-in-out",
    transformStyle: "preserve-3d",
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "spring", stiffness: 500, damping: 100 }}>
      <Stack overflow={"hidden"} alignItems={"center"} justifyContent={"center"} gap={"20px"} height={"100vh"}>
        <motion.div initial={{ translateY: "100px" }} animate={{ translateY: "0px" }} transition={{ type: "spring", stiffness: 500, damping: 100 }}>
          <Stack alignItems={"center"} justifyContent={"center"} gap={"20px"} height={"fit-content"}>
            <CheckCircleIcon sx={{ fontSize: "200px", color: "#4abe48" }} />
            <Typography variant="h4" sx={{ fontWeight: "200", textAlign: "center" }}>
              INTERVENCIÓN
              <br /> <span style={{ color: "#4abe48" }}> CONFIRMADA </span>
            </Typography>
          </Stack>
        </motion.div>
      </Stack>
    </motion.div>
  );
};

export default ConfirmedComponent;
