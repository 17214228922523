export const demoReviews = [
    {
      "author_name": "Jarl Moden",
      "author_url": "https://www.google.com/maps/contrib/104686022926858540874/reviews",
      "language": "en",
      "original_language": "en",
      "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLV4dXJ9ICYsDpwsbZQFHtJgqOEFnjMO783jWV72DAsI7Zu47k=s128-c0x00000000-cc-rp-mo",
      "rating": 5,
      "relative_time_description": "a year ago",
      "text": "I've been to this place a few times for service, upgrades and repairs. They have lots of experience and passion for Minis, the garage is always filled with Cooper S/JCWs cars. They are easy to communicate with on phone, whatsapp and email and provide relevant recommendations and updates. The manager speaks English quite well which is very helpful for non-native expat customers like me. Reasonable pricing, especially compared to BMW/Mini Official dealer locations. Highly recommended if you have a R53 or newer model and you live in Catalonia, well worth a longer drive than your nearest options.",
      "time": 1677701245,
      "translated": false
    },
    {
      "author_name": "Sergio Alcalá",
      "author_url": "https://www.google.com/maps/contrib/100476974647574744275/reviews",
      "language": "es",
      "original_language": "es",
      "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLv2rBoXQMKFmHpkaQ6goxlHWHH0zHm3biTDYLoS7t0xtC24MY3=s128-c0x00000000-cc-rp-mo-ba3",
      "rating": 5,
      "relative_time_description": "a month ago",
      "text": "Grandes profesionales! Me repararon mí Mini Cooper R56, ahora funciona como la seda!\nMuy contento con la atención, profesionalidad y todo el equipo humano del taller la Guarida!\nSin duda mí taller de confianza. Lo recomiendo 100%. Muchas gracias  a Nacho y todo su equipo de excelentes mecanic@s!",
      "time": 1723064231,
      "translated": false
    },
    {
      "author_name": "Jorge LaGr",
      "author_url": "https://www.google.com/maps/contrib/105860393997169895738/reviews",
      "language": "es",
      "original_language": "es",
      "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjU14DFTkUVHIopihrBKdlLh4ddyw0RXhY2dKYI9ESnY38-7X7w=s128-c0x00000000-cc-rp-mo",
      "rating": 5,
      "relative_time_description": "a month ago",
      "text": "Tras haber mirado varios Mini de segunda mano, me decidí por comprarlo en La Guarida. Lo primero porque eran los más interesantes, pero después me daba mucha más confianza que un particular. Después de haberlo hecho, he de decir que estoy encantado. Pero la sorpresa positiva es que Ferràn y Nacho no son super profesionales, si no que le ponen tanto cariño al coche que lo hacen suyo. Se nota que es un sitio donde saben lo que hacen y saben como han de ser las cosas bien hechas. En resumen, La Guarida es el lugar ideal para confiar tu Mini. 100% recomendable.",
      "time": 1721808206,
      "translated": false
    },
    {
      "author_name": "Laura Carrasco",
      "author_url": "https://www.google.com/maps/contrib/117064820717530891756/reviews",
      "language": "es",
      "original_language": "es",
      "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocINpBm3T5sNOfbB_49Dyhx-VfobfAFqpN6CqSSwBaNt0nU2ew=s128-c0x00000000-cc-rp-mo",
      "rating": 5,
      "relative_time_description": "4 months ago",
      "text": "Taller de confianza, me he sentido muy bien atendida y con mucha rapidez. Tenía que arreglar dos cositas pequeñas para pasar la ITV y no sólo me han atendido al instante, sinó que además no me han cobrado nada! Unos majos!",
      "time": 1713971809,
      "translated": false
    },
    {
      "author_name": "Àlex Poyo Muñoz",
      "author_url": "https://www.google.com/maps/contrib/102767731692108440965/reviews",
      "language": "es",
      "original_language": "es",
      "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjXCscJsX3DSZJwqH7V8c7H8zeQYKIdhcaS43I-fuihk36L9BKg=s128-c0x00000000-cc-rp-mo",
      "rating": 5,
      "relative_time_description": "4 months ago",
      "text": "Trato muy agradable. El personal se preocupa realmente por la situación particular de tu vehículo, escuchándote y ajustándose a tus necesidades y situación.   A pesar de ser especialista en Mini, aceptaron sin problemas BMW diésel. Definitivamente han ganado a un cliente fiel.\nÀlex",
      "time": 1714670535,
      "translated": false
    }
  ]