import { Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import styled from "styled-components";

const MessageComponent = ({ message, key }) => {
  //STYLES

  const renderDate = (date) => {
    return (
      new Date(date * 1000).toLocaleDateString() +
      "  " +
      new Date(date * 1000).toLocaleTimeString("es-ES", {
        hour: "numeric",
        minute: "numeric",
      })
    );
  };

  //RENDER

  if (message.type === "text") {
    if (!message.text) {
      return;
    }

    if (message.from_me) {
      return (
        <>
          <Stack key={key} sx={{ position: "relative", alignItems: "flex-end", width: "100%" }}>
            <Stack sx={{ position: "relative", padding: "5px", width: "fit-content", maxWidth: "70%", backgroundColor: "primary.main" }}>
              <Typography sx={{ padding: "5px 10px", color: "white" }} variant="body1">
                {message.emoji && (
                  <Stack
                    sx={{
                      position: "absolute",
                      bottom: "-15px",
                      right: "15px",
                      zIndex: "10",
                      fontSize: "2rem",
                      backgroundColor: "white",
                      border: "1px solid gray",
                      borderRadius: "50%",
                      width: "43px",
                      height: "43px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {message.emoji}
                  </Stack>
                )}
                <div dangerouslySetInnerHTML={{ __html: message.text?.body?.replace(/\n/g, "<br/>") }} />
              </Typography>
              <Typography sx={{ padding: "0px 10px", fontWeight: "300", color: "white", width: "fit-content", fontSize: "0.7rem" }}>{renderDate(message.timestamp)}</Typography>
              <Stack sx={{ width: "15px", height: "15px", backgroundColor: "primary.main", rotate: "45deg", position: "absolute", top: "calc(100% - 30px)", right: "-7.5px" }}></Stack>
            </Stack>
          </Stack>
        </>
      );
    } else {
      return (
        <>
          <Stack sx={{ alignItems: "flex-start", width: "100%" }}>
            <Stack sx={{ position: "relative", padding: "5px", width: "fit-content", maxWidth: "70%", backgroundColor: "secondary.light" }}>
              <Typography sx={{ padding: "5px 10px" }} variant="body1">
                {message.emoji && (
                  <Stack
                    sx={{
                      position: "absolute",
                      bottom: "-15px",
                      left: "15px",
                      zIndex: "10",
                      fontSize: "1.3rem",
                      backgroundColor: "white",
                      border: "1px solid gray",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {message.emoji}
                  </Stack>
                )}
                <div dangerouslySetInnerHTML={{ __html: message.text?.body.replace(/\n/g, "<br/>") }} />
              </Typography>
              <Typography sx={{ padding: "0px 10px", fontWeight: "300", width: "fit-content", fontSize: "0.7rem" }}>{renderDate(message.timestamp)}</Typography>
              <Stack sx={{ width: "15px", height: "15px", backgroundColor: "secondary.light", rotate: "45deg", position: "absolute", top: "calc(100% - 30px)", left: "-7.5px" }}></Stack>
            </Stack>
          </Stack>
        </>
      );
    }
  }

  if (message.type === "link_preview") {
    if (message.from_me) {
      return (
        <>
          <Stack sx={{ alignItems: "flex-end", width: "100%" }}>
            <Stack sx={{ position: "relative", padding: "5px", width: "fit-content", maxWidth: "70%", backgroundColor: "primary.main" }}>
              <Typography sx={{ padding: "5px 10px", color: "white" }} variant="body1">
                {message.link_preview?.body}
              </Typography>
              <Typography sx={{ padding: "0px 10px", fontWeight: "300", color: "white", width: "fit-content", fontSize: "0.7rem" }}>{renderDate(message.timestamp)}</Typography>
              <Stack sx={{ width: "15px", height: "15px", backgroundColor: "primary.main", rotate: "45deg", position: "absolute", top: "calc(100% - 30px)", right: "-7.5px" }}></Stack>
            </Stack>
          </Stack>
        </>
      );
    } else {
      return (
        <>
          <Stack sx={{ alignItems: "flex-start", width: "100%" }}>
            <Stack sx={{ position: "relative", padding: "5px", width: "fit-content", maxWidth: "70%", backgroundColor: "secondary.light" }}>
              <Typography sx={{ padding: "5px 10px" }} variant="body1">
                {message.link_preview?.body}
              </Typography>
              <Typography sx={{ padding: "0px 10px", fontWeight: "300", width: "fit-content", fontSize: "0.7rem" }}>{renderDate(message.timestamp)}</Typography>
              <Stack sx={{ width: "15px", height: "15px", backgroundColor: "secondary.light", rotate: "45deg", position: "absolute", top: "calc(100% - 30px)", left: "-7.5px" }}></Stack>
            </Stack>
          </Stack>
        </>
      );
    }
  }
};

export default MessageComponent;
