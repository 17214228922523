import { atom } from "jotai";
import { InterventionsEntity, PartsEntity, VehicleEntity, RevisionsEntity } from "../mappers/Vehicle.Mapper";

export const tokenAtom = atom();

export const snackBarAtom = atom({ open: false, mensaje: "" });

export const urlParam = atom();
export const carOwnerAtom = atom();
export const userVehicleAtom = atom(VehicleEntity);
export const vehicleInterventionAtom = atom(InterventionsEntity);

export const fieldsMapAtom = atom({
  TextChange_Aceite_Motor_Meses: PartsEntity,
  TextChange_Aceite_Motor_Kilometros: PartsEntity,
  TextChange_Neumaticos_Delanteros_Meses: PartsEntity,
  TextChange_Neumaticos_Delanteros_Kilometros: PartsEntity,
  TextChange_Neumaticos_Traseros_Meses: PartsEntity,
  TextChange_Neumaticos_Traseros_Kilometros: PartsEntity,
  TextChange_Filtro_Aire_Meses: PartsEntity,
  TextChange_Filtro_Aire_Kilometros: PartsEntity,
  TextChange_Filtro_Combustible_Meses: PartsEntity,
  TextChange_Filtro_Combustible_Kilometros: PartsEntity,
  TextChange_Filtro_Habitaculo_Meses: PartsEntity,
  TextChange_Filtro_Habitaculo_Kilometros: PartsEntity,
  TextChange_Filtro_Aceite_Meses: PartsEntity,
  TextChange_Filtro_Aceite_Kilometros: PartsEntity,
  TextChange_Correa_Distribuicion_Meses: PartsEntity,
  TextChange_Correa_Distribuicion_Kilometros: PartsEntity,
  TextChange_Correa_Acessorios_Meses: PartsEntity,
  TextChange_Correa_Acessorios_Kilometros: PartsEntity,
});

//globalSettings
export const userAtom = atom();
export const demoAtom = atom();
export const adminAtom = atom();
export const firstLoginAtom = atom();

//SideBarComponent
export const modelInterventionAtom = atom();
export const manufacturerInterventionAtom = atom();
export const plateInterventionAtom = atom();
export const clientNameAtom = atom();
export const clientSurnameAtom = atom();
export const dateInterventionAtom = atom();
export const kmInterventionAtom = atom();
export const commentsInterventionAtom = atom();

//changedItems
export const changedItemsAtom = atom([PartsEntity]);
export const partsIsSelectedAtom = atom([]);

export const reviewsAtom = atom([PartsEntity]);

//CalendarSettings

export const selectedMonthAtom = atom();

//LayoutSettings

export const sideBarDenseAtom = atom(false);

//Interventions
export const interventionEditModeAtom = atom(false);
export const checkInterventionsAtom = atom(false);

//Toast

export const toastOpenAtom = atom(false);
export const toastTextAtom = atom("Éxito");
export const toastStyleAtom = atom("success");
export const toastPositionXAtom = atom("right");
export const toastPositionYAtom = atom("bottom");
export const toastMarginAtom = atom(false);

//VehiclePage

export const vehicleNameAtom = atom();
export const userNameAtom = atom();

//Notification

export const notificationAtom = atom([]);
