import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import SmsIcon from "@mui/icons-material/Sms";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, Collapse, InputAdornment, Link, Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { demoAtom, userNameAtom, vehicleNameAtom } from "../../../context/atoms/Atoms.Storage";
import { useAtom } from "jotai";
import { getStorageObject } from "../../../utils/LocalStorage";
import { useToast } from "../../../components/toastComponent/toast.Actions";
import { clearUnreadMessages } from "../../../services/API/Whapi.Services";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";

function createData(id, name, vehiculos, phone, unreadMessages) {
  return {
    id,
    name,
    vehiculos,
    phone,
    unreadMessages,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "vehiculos",
    numeric: false,
    disablePadding: false,
    label: "Vehiculo(s)",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Teléfono",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: "primary.main" }}>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              <span style={{ color: "white", fontWeight: "200", fontSize: "1.2rem" }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CustomTableRow({ setUserName, setUserPhone, row, vehicles, setWhatsappModalIsOpen, setRows, setUserId, index, setVehiclesWhatsapp }) {
  const [demo, setDemo] = useAtom(demoAtom);
  const [vehicleName, setVehicleName] = useAtom(vehicleNameAtom);
  const [userClientName, setUserClientName] = useAtom(userNameAtom);

  const handleRowClick = (vehicle) => {
    setVehicleName(`${vehicle.vehicle_manufacturer?.name || ""} ${vehicle.vehicle_model?.name || ""}`);
    setUserClientName(`${vehicle.user_owner.name_first} ${vehicle.user_owner.name_last}`);
    navigate(`/vehiculo/${vehicle.id}`);
  };

  const [open, setOpen] = useState(false);
  const openBox = () => {
    setOpen(!open);
  };

  const WhatsappSetter = async (event) => {
    event.stopPropagation();
    setUserName(row.name);
    setUserPhone(row.phone);
    setUserId(row.id);
    setWhatsappModalIsOpen(true);

    setVehiclesWhatsapp(vehicles.filter((vehicle) => vehicle.user_owner.id === row.id));

    const userRepairShop = getStorageObject("repairShopId");

    const token = getStorageObject("token");

    await clearUnreadMessages(getStorageObject("token"), row.id, userRepairShop);

    setRows((prevRows) => prevRows.map((r) => (r.id === row.id ? { ...r, notification_whatsapps: [] } : r)));
  };

  const userData = getStorageObject("userData");

  const { openCustomToast } = useToast();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const whatsappWalkthrough = queryParams.get("whatsappWalkthrough");

  return (
    <>
      <TableRow hover onClick={openBox} role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: "pointer", position: "relative" }}>
        <TableCell padding="checkbox">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          {row.name}
        </TableCell>
        <TableCell sx={{ textTransform: "capitalize" }} align="left">
          {row.vehiculos}
        </TableCell>
        <TableCell align="left">
          {row.phone || "Sin especificar"}
          {row.phone && (
            <Link href={`https://wa.me/34${row.phone?.replace(/\D/g, "")}`} target="_blank">
              <OpenInNewIcon sx={{ marginTop: "5px", height: "15px", color: "black" }} />
            </Link>
          )}
        </TableCell>
        {whatsappWalkthrough && index === 0 && (
          <motion.div
            style={{ position: "absolute", right: "56px", top: "16px" }}
            initial={{ transform: "translateX(0px)" }}
            animate={{ transform: "translateX(10px)" }}
            transition={{ duration: 0.4, repeatType: "reverse", repeat: Infinity }}
          >
            <ArrowForwardIcon sx={{ height: "25px", width: "25px", color: "primary.main" }} />
          </motion.div>
        )}
        <Tooltip
          arrow
          placement="left"
          title={(userData && userData.id_user_subscription === 1) || (userData && userData.id_user_subscription === 2) ? "" : "Esta función sólo está disponible para usuarios Turbo o Pro"}
        >
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              {
                (userData && userData.id_user_subscription === 1) || (userData && userData.id_user_subscription === 2)
                  ? WhatsappSetter(event)
                  : openCustomToast("Esta funcion sólo esta disponible para usuarios Turbo o Pro", "warning", false);
              }
            }}
            sx={{ position: "absolute", height: "40px", width: "40px", right: "10px", top: "0", bottom: "0", margin: "auto", "&:hover": { cursor: "pointer" } }}
          >
            {(userData && userData.id_user_subscription === 1) || (userData && userData.id_user_subscription === 2) ? (
              <Badge
                id="whatsappbadge"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "primary.main",
                    height: "20px",
                    width: "20px",
                  },
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                badgeContent={row?.unreadMessages || 0}
              >
                <img style={{ margin: "3px", height: "20px" }} src="/img/ico_whatsapp.svg" alt="" />{" "}
              </Badge>
            ) : (
              <img style={{ height: "20px" }} src="/img/ico_whatsapp_grey.svg" />
            )}
          </IconButton>
        </Tooltip>
      </TableRow>
      <TableRow sx={{ cursor: "pointer", marginLeft: "25px", padding: "0" }}>
        <TableCell style={{ backgroundColor: "#f1f1f1", paddingBottom: 0, paddingTop: 0, boxShadow: "inset 0px 20px 30px -20px rgba(0,0,0,0.1)" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody style={{ position: "relative" }}>
                  <TableRow>
                    <TableCell align="left" sx={{ paddingLeft: "50px" }}>
                      <b>Vehiculo</b>
                    </TableCell>
                    <TableCell>
                      <b>Matricula</b>
                    </TableCell>
                    <TableCell>
                      <b>Última intervención</b>
                    </TableCell>
                    <TableCell>
                      <b>Revisar / sustituir</b>
                    </TableCell>
                  </TableRow>

                  {vehicles
                    .filter((vehicle) => vehicle.id_user_owner == row.id)
                    .map((vehicle, index) => (
                      <TableRow
                        onClick={() => {
                          handleRowClick(vehicle);
                        }}
                        sx={{ "&:hover": { paddingLeft: "54px" } }}
                        key={index}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            textTransform: "capitalize",
                            minHeight: "100%!important",
                            cursor: "pointer",
                            borderRadius: "50px",
                            paddingLeft: "50px",
                            transition: "0.2s",
                            "&:hover": { paddingLeft: "54px" },
                          }}
                          component="th"
                          scope="row"
                        >
                          {demo !== true
                            ? vehicle.vehicle_manufacturer?.name && vehicle.vehicle_model?.name
                              ? `${vehicle.vehicle_manufacturer.name} ${vehicle.vehicle_model.name}`
                              : vehicle.vehicle_manufacturer?.name
                              ? vehicle.vehicle_manufacturer.name
                              : vehicle.vehicle_model?.name
                              ? vehicle.vehicle_model.name
                              : "Sin especificar"
                            : `${vehicle.manufacturer_name || ""} ${vehicle.model_name || "Sin especificar"}`}
                        </TableCell>
                        <TableCell>{vehicle?.plate || "Sin matricula"}</TableCell>
                        <TableCell>
                          {vehicle && vehicle?.interventions && vehicle?.interventions?.length > 0 ? new Date(vehicle?.interventions[0]?.created_at).toLocaleDateString() : "Sin intervenciones"}
                        </TableCell>
                        <TableCell>{vehicle.interventions[0]?.intervention_carcomponents ? vehicle.interventions[0]?.intervention_carcomponents.carcomponent.name : "Sin piezas"}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function DataTable({ setVehiclesWhatsapp, filteredUsers, setFilteredUsers, vehicles, searchValue, setSearchValue, setWhatsappModalIsOpen, setUserName, setUserPhone, setUserId }) {
  const [demo, setDemo] = useAtom(demoAtom);
  const rows = filteredUsers.map((user) => {
    const userVehicles = vehicles.filter((vehicle) => vehicle.id_user_owner == user.id);

    let userVehiclesString;

    if (demo !== true) {
      userVehiclesString = userVehicles
        .map((vehicle) =>
          vehicle.vehicle_manufacturer?.name && vehicle.vehicle_model?.name
            ? `${vehicle.vehicle_manufacturer.name} ${vehicle.vehicle_model.name}`
            : vehicle.vehicle_manufacturer?.name
            ? vehicle.vehicle_manufacturer.name
            : vehicle.vehicle_model?.name
            ? vehicle.vehicle_model.name
            : "Sin especificar"
        )
        .join(", ");
    } else {
      userVehiclesString = userVehicles.map((vehicle) => `${vehicle.manufacturer_name.charAt(0).toUpperCase() + vehicle.manufacturer_name.slice(1)} ${vehicle.model_name}`).join(", ");
    }

    return createData(user.id, `${user.name_first} ${user.name_last}`, userVehiclesString, user.phone_number, (user.notification_whatsapps && user.notification_whatsapps[0]?.unread_messages) || 0);
  });
  const rowHeight = 57;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(Math.floor(window.innerHeight / rowHeight) - 6);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(Math.floor(window.innerHeight / rowHeight) - 6);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const navigate = useNavigate();
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  let visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const setVisibleRows = (data) => {
    visibleRows = data;
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "0 0 20px 0 rgba(0,0,0,0.14)" }}>
        <TableContainer>
          <Stack sx={{ paddingRight: "20px", alignItems: "flex-end", backgroundColor: "primary.main" }}>
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{
                paddingTop: "20px",
                width: "30%",
                color: "white",
                "& input": {
                  color: "white",
                },
                "& input::placeholder": {
                  color: "white",
                  opacity: 1,
                  fontWeight: "200",
                  fontSize: "0.8rem",
                },
                "& .MuiInput-underline::before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline.Mui-focused::before": {
                  borderBottomColor: "white",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white" }} />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
              placeholder="Matricula, telefono, nombre, marca..."
              variant="standard"
            />
          </Stack>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const open = false;

                const openBox = () => {
                  open = !open;
                };

                return (
                  <CustomTableRow
                    index={index}
                    setUserId={setUserId}
                    setRows={setFilteredUsers}
                    setUserName={setUserName}
                    setUserPhone={setUserPhone}
                    setWhatsappModalIsOpen={setWhatsappModalIsOpen}
                    setVehiclesWhatsapp={setVehiclesWhatsapp}
                    key={row.id}
                    row={row}
                    vehicles={vehicles}
                  />
                );
              })}
              {emptyRows > 0 && (
                <>
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </Box>
  );
}
