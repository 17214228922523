export const emailValidator = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase()) || "Ingresa un correo valido";
}

export const phoneValidator = (phone) => {
    const regex = /^\d{9}$/;
    return regex.test(String(phone)) || "Ingresa un número valido";
}

export const nameValidator = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\-'. ]+$/
    return regex.test(String(value)) || "Ingresa un nombre de taller válido";
}  

export const contactValidator = (value) => {
    const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\-'. ]+$/
    return regex.test(String(value)) || "Ingresa un nombre válido";
}  

export const urlValidator = (value) => {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    return regex.test(String(value)) || "Ingresa una url válida";
}