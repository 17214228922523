import React, { useEffect, useState } from "react";
import MainContent from "../../components/MainContent.component.jsx";
import FadeInComponent from "../../components/fadeIn.Component.jsx";
import HeaderComponent from "../../components/Header.Component.jsx";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { getAllModels } from "../../services/API/Model.Services.js";
import { getAllByRepairshopIdUsers } from "../../services/API/User.Services.js";
import { getAllByRepairshopIdVehicles } from "../../services/API/Vehicle.Services.js";
import { getAllManufacturers } from "../../services/API/Manufacturer.Services.js";
import { useAtom } from "jotai";
import { userAtom } from "../../context/atoms/Atoms.Storage.jsx";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/Loading.Component.jsx";
import VehicleModalComponent from "./components/VehicleModal.Component.jsx";
import { getStorageObject } from "../../utils/LocalStorage.js";
import BackButton from "../../components/backButton/BackButton.Component.jsx";

const NewInterventionPage = () => {
  //HOOKS

  const navigate = useNavigate();

  //STATES

  const [vehiclesState, setVehiclesState] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [userState, setUserState] = useAtom(userAtom);
  const [usersState, setUsersState] = useState([]);
  const [licensePlateState, setLicensePlateState] = useState("");
  const [vehicleDataState, setVehicleDataState] = useState({});
  const [vehicleModalIsOpenState, setVehicleModalIsOpenState] = useState(false);
  const [vehicleConfirmModalIsOpenState, setVehicleConfirmModalIsOpenState] = useState(false);
  const [manufacturersState, setManufacturersState] = useState();
  const [modelsState, setModelsState] = useState();
  const [clientIdState, setClientIdState] = useState(1);

  const [energy, setEnergy] = useState();

  //USE EFFECT

  useEffect(() => {
    const fetchData = async () => {
      const token = getStorageObject("token");
      const repairShopId = getStorageObject("repairShopId");

      try {
        // Fetch vehicles
        const vehiclesResponse = await getAllByRepairshopIdVehicles(token, repairShopId);
        const vehicles = vehiclesResponse?.data || []; // If no vehicles, default to an empty array

        // Fetch users
        const usersResponse = await getAllByRepairshopIdUsers(token, repairShopId);
        const users = usersResponse?.data || []; // If no users, default to an empty array

        // Fetch manufacturers
        const manufacturersResponse = await getAllManufacturers(token);
        const manufacturers = manufacturersResponse?.data || []; // If no manufacturers, default to an empty array

        // Fetch models
        const modelsResponse = await getAllModels(token);
        const models = modelsResponse?.data || []; // If no models, default to an empty array

        // Set state for vehicles and users
        setVehiclesState(vehicles);
        setUsersState(users);

        // Transform and set state for models
        const transformedModels = models.map((model) => ({
          id: model.id,
          value: model.id,
          label: model.name,
          manufacturer: model.id_vehicle_manufacturer,
        }));
        setModelsState(transformedModels);

        // Transform and set state for manufacturers
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const transformedManufacturers = manufacturers.map((manufacturer) => ({
          id: manufacturer.id,
          value: manufacturer.id,
          label: capitalize(manufacturer.name),
        }));
        setManufacturersState(transformedManufacturers);

        setLoadingState(false);
      } catch (error) {
        console.error(error);
        setLoadingState(false); // Set loading to false even if there is an error
      }
    };

    fetchData();
  }, [userState]);

  //RENDER

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <FadeInComponent>
      {vehicleModalIsOpenState && (
        <VehicleModalComponent
          energy={energy}
          setEnergy={setEnergy}
          modelsState={modelsState}
          clientIdState={clientIdState}
          usersState={usersState}
          manufacturersState={manufacturersState}
          licensePlateState={licensePlateState}
          setVehicleModalIsOpenState={setVehicleModalIsOpenState}
        />
      )}
      {vehicleConfirmModalIsOpenState && (
        <VehicleModalComponent
          modelsState={modelsState}
          clientIdState={clientIdState}
          usersState={usersState}
          licensePlateState={licensePlateState}
          setVehicleModalIsOpenState={setVehicleConfirmModalIsOpenState}
          defaultStep={6}
          vehicleDataState={vehicleDataState}
        />
      )}
      <MainContent>
        <HeaderComponent text={"Nueva Intervención"} icon={<NoteAddIcon sx={{ color: "#e94e1b", height: "40px", width: "40px" }} />} />
        <Stack sx={{ position: "absolute", top: "10px", left: "10px" }}>
          <BackButton route={"/intervenciones"} />
        </Stack>
        <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>Introduce la matricula</Typography>

        <Stack p={"10px"} maxHeight={"60%"} overflow={"auto"} direction={"column"} gap={"10px"} justifyContent={"center"} alignItems={"center"}>
          <TextField
            type="text"
            label="Matricula"
            value={licensePlateState}
            onChange={(e) => {
              if (e.target.value.length <= 7) {
                setLicensePlateState(e.target.value.toUpperCase());
              }
            }}
          />

          {vehiclesState &&
            manufacturersState &&
            modelsState &&
            licensePlateState &&
            vehiclesState
              .filter((vehicle) => vehicle.plate.includes(licensePlateState))
              .map((vehicle) => {
                return (
                  <Button
                    onClick={() => {
                      setVehicleDataState(vehicle);
                      setVehicleConfirmModalIsOpenState(true);
                    }}
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    <Typography>
                      {manufacturersState.find((m) => m.value === vehicle.id_vehicle_manufacturer)?.label} {modelsState.find((m) => m.value === vehicle.id_vehicle_model)?.label} <b>{vehicle.plate}</b>
                    </Typography>
                  </Button>
                );
              })}

          {vehiclesState && licensePlateState && vehiclesState.filter((vehicle) => vehicle.plate.includes(licensePlateState)).length === 0 && (
            <>
              <Typography sx={{ padding: "10px", textTransform: "uppercase", fontWeight: "300" }}>El vehiculo no existe</Typography>
              <Button
                onClick={() => {
                  setVehicleModalIsOpenState(true);
                }}
                variant="outlined"
              >
                Crear vehiculo
              </Button>
            </>
          )}
        </Stack>
      </MainContent>
    </FadeInComponent>
  );
};

export default NewInterventionPage;
