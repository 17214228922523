import React, { useEffect, useState } from "react";
import { Badge, IconButton, Stack, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";
import { AnimatePresence, motion } from "framer-motion";
import NotificationComponent from "./components/notification.Component.jsx";
import { getStorageObject } from "../../utils/LocalStorage.js";
import { getByUserIdNotifications } from "../../services/API/Notification.Services.js";
import { updateNotification } from "../../services/API/Notification.Services.js";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { checkInterventionsAtom, demoAtom, notificationAtom } from "../../context/atoms/Atoms.Storage";

const notificationCenter = () => {
  //HOOKS

  const location = useLocation();

  //STATES

  const [notificationCenterIsOpenState, setNotificationCenterIsOpenState] = useState(false);
  const [notificationsState, setNotificationsState] = useAtom(notificationAtom);
  const [checkInterventions, setCheckInterventions] = useAtom(checkInterventionsAtom);
  const [demo, setDemo] = useAtom(demoAtom);

  //USE EFFECTS

  useEffect(() => {
    const token = getStorageObject("token");
    const userMainId = getStorageObject("userData")?.id;
    if (demo === false) {
      getByUserIdNotifications(token, userMainId)
        .then(({ data: notifications, status, ok }) => {
          setNotificationsState(notifications);
          if (
            notifications.filter((notification) => notification.is_read === false).length > 0 &&
            notifications.filter((notification) => notification.notification.is_permanent === true).filter((notification) => notification.is_closed === true).length > 0
          ) {
            setNotificationCenterIsOpenState(true);
          }
        })
        .catch((error) => {
          setNotificationCenterIsOpenState(false);
          console.error("Error fetching notifications:", error);
        });
    } else if (demo === true) {
      setNotificationCenterIsOpenState(false);
    }
  }, [checkInterventions]);

  const markAllAsRead = async () => {
    if (notificationsState && notificationsState.some((notification) => !notification.is_read).length > 0) {
      const token = getStorageObject("token");
      const userMainId = getStorageObject("userData").id;

      try {
        await updateNotification(token, userMainId, "is_read");
      } catch (error) {
        console.error(error);
      }

      const newNotifications = notificationsState.map((notification) => {
        return { ...notification, is_read: true };
      });
      setNotificationsState(newNotifications);
    }
  };

  return (
    <>
      {location.pathname === "/intervencion" || location.pathname === "/leads" ? null : (
        <AnimatePresence>
          {!notificationCenterIsOpenState && (
            <IconButton
              onClick={() => {
                setNotificationCenterIsOpenState(!notificationCenterIsOpenState);
              }}
              sx={{ height: "fit-content", width: "fit-content", zIndex: "1000" }}
            >
              <Badge
                sx={{ color: "primary.main" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                badgeContent={notificationsState?.filter((notification) => notification.is_closed === false).length}
              >
                <NotificationsNoneIcon sx={{ height: "30px", width: "30px", color: notificationsState?.filter((notification) => notification.is_closed === false).length > 0 ? "primary.main" : "grey" }} />
              </Badge>
            </IconButton>
          )}

          {notificationCenterIsOpenState && (
            <motion.div
              key={"notificationCenter"}
              style={{ zIndex: "1000", backgroundColor: "#f5f5f5", position: "fixed", top: "20px", right: "30px" }}
              initial={{ translateY: -300, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1 }}
              exit={{ translateY: -300, opacity: 0 }}
              transition={{ type: "spring", stiffness: 350, damping: 35 }}
            >
              <Stack
                sx={{
                  height: "fit-content",
                  zIndex: "1001",
                  backgroundColor: "white",
                  width: "400px",
                  boxShadow: "0 0 20px 5px rgba(0, 0, 0, 0.15)",
                  padding: "5px",
                  paddingBottom: "15px",
                  maxHeight: "calc(100vh - 60px)",
                }}
              >
                <Stack sx={{ alignItems: "center", justifyContent: "space-between" }} direction={"row"}>
                  <Typography sx={{ padding: "15px", paddingLeft: "15px", fontWeight: "200" }}>NOTIFICACIONES</Typography>
                  <IconButton
                    onClick={() => {
                      setNotificationCenterIsOpenState(!notificationCenterIsOpenState), markAllAsRead();
                    }}
                    sx={{ position: "absolute", top: "3px", right: "14px", height: "fit-content", width: "fit-content", zIndex: "1000" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Stack marginTop={"10px"} direction={"column"} gap={"10px"}>
                  {notificationsState && notificationsState?.filter((notification) => notification.is_closed === false).length > 0 ? (
                    notificationsState
                      .sort((a, b) => {
                        if (a.type === "alert" && b.type !== "alert") {
                          return -1;
                        }
                        if (a.type !== "alert" && b.type === "alert") {
                          return 1;
                        }
                        return 0;
                      })
                      .filter((notification) => notification.is_closed === false)
                      .map((notification, index) => (
                        <NotificationComponent
                          route={notification.notification.route}
                          setNotificationCenterIsOpenState={setNotificationCenterIsOpenState}
                          id={notification.id}
                          notifications={notificationsState}
                          setNotificationsState={setNotificationsState}
                          isPermanent={notification.notification.is_permanent}
                          text={notification.notification.message}
                          type={notification.notification.type}
                          key={index}
                          isRead={notification.is_read}
                        />
                      ))
                  ) : (
                    <Typography sx={{ padding: "10px", fontSize: "0.8rem", fontWeight: "200", textAlign: "center" }}>No hay notificaciones</Typography>
                  )}
                </Stack>
              </Stack>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
};

export default notificationCenter;
