import React, { useEffect, useState } from "react";
import ModalComponent from "../../../components/Modal.Component";
import { IconButton, Select, Stack, styled, TextField, Typography, MenuItem, FormControl, InputLabel, Checkbox } from "@mui/material";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import TireRepairIcon from "@mui/icons-material/TireRepair";
import CloseIcon from "@mui/icons-material/Close";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import { useToast } from "../../../components/toastComponent/toast.Actions";

const CustomCheckbox = ({ title, setFunction, state }) => {
  return (
    <Stack
      padding={"0 20px"}
      width={"calc(100% - 40px)"}
      minHeight={"50px"}
      maxWidth={"100%"}
      backgroundColor={state ? "#ea5828" : "#dedede"}
      color={state ? "white" : "black"}
      direction={"row"}
      gap={"10px"}
      transition={"0.3"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography width={"100%"} textAlign={"left"} sx={{ fontWeight: "300" }}>
        {title}
      </Typography>
      <Checkbox
        label="Start/Stop"
        onChange={(e) => setFunction(e.target.checked)}
        checked={state}
        sx={{
          color: "white",
          "& .MuiSvgIcon-root": {
            borderRadius: 0,
            fill: state ? "white" : "black",
          },
          "&.Mui-checked": {
            borderRadius: 0,
            color: state ? "white" : "black",
          },
        }}
      />
    </Stack>
  );
};

const SquareTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
  },
  width: "100%",
}));

const CarcomponentConfigModal = ({ modifiedCarcomponents, carcomponent, closeModal, handleType, carcomponentType }) => {
  const { openCustomToast } = useToast();

  const [isStartStop, setIsStartStop] = useState(false);

  const [sameTires, setSameTires] = useState(carcomponent.id === 3 ? true : false);

  const [batteryDimensions, setBatteryDimensions] = useState();

  return (
    <ModalComponent>
      <Stack sx={{ gap: "0px", backgroundColor: "white" }}>
        <Stack alignItems={"center"} padding={"5px 15px"} direction={"row"} gap={"20px"} justifyContent={"space-between"} boxShadow={"0px 5px 10px 0 rgba(0, 0, 0, 0.20)"}>
          <Stack direction={"row"} gap={"10px"}>
            {carcomponent.id === 1 && <WaterDropIcon />}
            {carcomponent.id === 19 && <BatteryChargingFullIcon />}
            {carcomponent.id === 2 && <TireRepairIcon />}
            {carcomponent.id === 3 && <TireRepairIcon />}
            <Typography>{carcomponent.name} - Configuración</Typography>
          </Stack>
          <IconButton
            onClick={() => {
              closeModal();
              openCustomToast("Configuración guardada", "success", false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {/* ACEITE MOTOR */}
        {carcomponent.id === 1 && (
          <Stack padding={"20px"} gap={"10px"}>
            <SquareTextField value={carcomponentType} onChange={handleType} label="Tipo de aceite" sx={{ width: "100%" }} />
          </Stack>
        )}

        {/* NEUMÁTICOS */}
        {(carcomponent.id === 2 || carcomponent.id === 3) && (
          <Stack padding={"20px"} gap={"20px"}>
            {carcomponent.id === 3 && <CustomCheckbox title="Neumáticos traseros iguales" setFunction={setSameTires} state={sameTires} />}
            <SquareTextField disabled={sameTires} value={sameTires ? "Iguales" : carcomponentType} onChange={handleType} label="Tipo de neumáticos" sx={{ width: "100%" }} />
          </Stack>
        )}

        {/* BATERÍA */}
        {carcomponent.id === 19 && (
          <Stack padding={"20px"} alignItems={"center"} gap={"10px"}>
            <Stack direction={"row"} gap={"10px"}>
              <Stack gap={"10px"}>
                <Typography>Battery Health</Typography>
                <SquareTextField type="number" label="Resting Voltage (V)" sx={{ width: "100%" }} />
                <SquareTextField type="number" label="Starting Test (V)" sx={{ width: "100%" }} />
                <SquareTextField type="number" label="Charge-Discharge Test (%)" sx={{ width: "100%" }} />
                <SquareTextField type="number" label="Capacity Test (%)" sx={{ width: "100%" }} />
                <SquareTextField type="number" label="Resting Consumption Test (A)" sx={{ width: "100%" }} />
              </Stack>
              <Stack gap={"10px"}>
                <Typography>Battery caracteristics</Typography>
                <Stack paddingBottom={"6px"} direction={"row"} gap={"10px"}>
                  <CustomCheckbox title="Start/Stop battery" setFunction={setIsStartStop} state={isStartStop} />
                </Stack>
                <FormControl fullWidth>
                  <InputLabel>Dimensions</InputLabel>
                  <Select MenuProps={{ disablePortal: true }} value={batteryDimensions} onChange={(e) => setBatteryDimensions(e.target.value)} label="Dimensions" sx={{ width: "100%" }}>
                    <MenuItem value="L0">L0 (175x175x190 mm)</MenuItem>
                    <MenuItem value="L1">L1 (207x175x190 mm)</MenuItem>
                    <MenuItem value="L2">L2 (242x175x190 mm)</MenuItem>
                    <MenuItem value="L3">L3 (278x175x190 mm)</MenuItem>
                    <MenuItem value="L4">L4 (315x175x190 mm)</MenuItem>
                    <MenuItem value="L5">L5 (353x175x190 mm)</MenuItem>
                    <MenuItem value="H3">H3 (353x175x190 mm)</MenuItem>
                    <MenuItem value="H4">H4 (392x175x190 mm)</MenuItem>
                    <MenuItem value="H5">H5 (430x175x190 mm)</MenuItem>
                  </Select>
                </FormControl>
                <SquareTextField type="number" label="Capacity (Ah)" sx={{ width: "100%" }} />
                <SquareTextField type="number" label="Power (CCA)" sx={{ width: "100%" }} />
                <FormControl>
                  <InputLabel>Polarity</InputLabel>
                  <Select MenuProps={{ disablePortal: true }} label="Polarity" sx={{ width: "100%" }}>
                    <MenuItem value="+/- (L-R)">+/- (L-R)</MenuItem>
                    <MenuItem value="-/+ (L-R)">-/+ (L-R)</MenuItem>
                  </Select>
                </FormControl>
                {isStartStop && (
                  <FormControl>
                    <InputLabel>Start/Stop system type</InputLabel>
                    <Select MenuProps={{ disablePortal: true }} label="Sistema Start/Stop" sx={{ width: "100%" }}>
                      <MenuItem value="AGM">AGM</MenuItem>
                      <MenuItem value="EFB">EFB</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </ModalComponent>
  );
};

export default CarcomponentConfigModal;
