import { Button, ButtonGroup, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, InputAdornment, InputLabel, IconButton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import CarcomponentComponent from "./Carcomponent.Component";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ConfirmModalComponent from "../../../components/confirmModal/ConfirmModal.Component";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const NavigatorComponent = ({
  demo,
  vehicleState,
  isConfirmed,
  carcomponentsState,
  modifiedCarcomponents,
  handleComponentChange,
  handleConfirmIntervention,
  isFirstIntervention,
  disableConfirmIntervention,
}) => {
  const [filterValue, setFilterValue] = useState("all");

  const [responsiveState, setResponsiveState] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [confirmModal, setConfirmModal] = useState(false);

  const handleSearchTermChange = (event) => {
    setFilterValue("all");
    setSearchTerm(event.target.value);
  };

  const pieceOrder = [1, 7, 4, 6, 2, 3, 10, 11, 12, 13, 23, 5, 21, 20, 17, 19, 18, 16, 24, 15, 22, 9, 8];

  const orderedComponents = [...carcomponentsState].sort((a, b) => {
    return pieceOrder.indexOf(a.id) - pieceOrder.indexOf(b.id);
  });

  const handleClearSearch = () => setSearchTerm("");

  const filterIds = {
    engine: [1, 4, 5, 6, 7, 8, 9, 16, 21, 24, 25],
    suspension: [14, 15, 17, 22],
    other: [18, 19, 20, 23],
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1468) {
        setResponsiveState(true);
      } else {
        setResponsiveState(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {confirmModal && <ConfirmModalComponent confirmText={"¿Deseas confirmar la intervención?"} handleConfirm={() => handleConfirmIntervention()} handleCancel={() => setConfirmModal(false)} />}{" "}
      <Stack
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "calc(100% - 300px)",
          padding: "10px",
          paddingTop: "90px",
          height: "calc(100vh - 100px)",
          maxHeight: "100vh",
          overflow: "auto",
        }}
      >
        <ToggleButtonGroup
          sx={{
            position: "fixed",
            width: "fit-content",
            zIndex: 3,
            top: "15px",
            left: `calc(50% + 150px)`,
            transform: "translateX(-50%)",
            backgroundColor: "white",
            borderRadius: "0px",
            boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.2)",
          }}
          value={filterValue}
          onChange={(event, value) => setFilterValue(value)}
          exclusive
        >
          <ToggleButton sx={{ borderRadius: "0" }} value="all">
            Todo
          </ToggleButton>
          <ToggleButton sx={{ borderRadius: "0" }} value="engine">
            Motor
          </ToggleButton>
          <ToggleButton sx={{ borderRadius: "0" }} value="suspension">
            Rodaje
          </ToggleButton>
          <ToggleButton sx={{ borderRadius: "0" }} value="other">
            Otros
          </ToggleButton>
          <TextField
            InputProps={{
              sx: { borderRadius: "0" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm && (
                    <IconButton onClick={handleClearSearch} aria-label="clear" edge="end" disabled={!searchTerm}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            onChange={handleSearchTermChange}
            placeholder="Buscar"
            variant="outlined"
            fullWidth
          />
        </ToggleButtonGroup>
        {filterValue === "all" && (
          <motion.div style={{ width: "100%" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "spring", stiffness: 500, damping: 100 }}>
            <Stack sx={{ width: "100%", height: "100%" }} direction={"row"} alignItems={"flex-start"} gap={"20px"} justifyContent={"center"} flexWrap={"wrap"}>
              {orderedComponents
                .filter((carcomponent) => {
                  const normalizeString = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                  return !searchTerm || normalizeString(carcomponent.name.toLowerCase()).includes(normalizeString(searchTerm.toLowerCase()));
                })
                .map((carcomponent) => (
                  <CarcomponentComponent
                    demo={demo}
                    isFirstIntervention={isFirstIntervention}
                    modifiedCarcomponents={modifiedCarcomponents}
                    handleComponentChange={handleComponentChange}
                    key={carcomponent._id}
                    carcomponent={carcomponent}
                  />
                ))}{" "}
            </Stack>
          </motion.div>
        )}
        {filterValue === "engine" && (
          <motion.div style={{ maxHeight: "calc(100vh-300px)" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "spring", stiffness: 500, damping: 100 }}>
            <Stack height={"100%"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <Stack direction={"row"} width={"100%"} gap={"20px"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"}>
                {carcomponentsState
                  .filter((carcomponent) => filterIds.engine.includes(carcomponent.id))
                  .map((carcomponent) => (
                    <CarcomponentComponent
                      demo={demo}
                      isFirstIntervention={isFirstIntervention}
                      modifiedCarcomponents={modifiedCarcomponents}
                      handleComponentChange={handleComponentChange}
                      key={carcomponent._id}
                      carcomponent={carcomponent}
                    />
                  ))}
              </Stack>
              {!responsiveState && (
                <Stack height={"100%"} width={"30%"}>
                  <img style={{ right: "0", margin: "auto", zIndex: "-20", width: "100%" }} src="/img/engine_illustration.webp"></img>
                </Stack>
              )}
            </Stack>
          </motion.div>
        )}
        {filterValue === "suspension" && (
          <motion.div style={{ width: "100%" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "spring", stiffness: 500, damping: 100 }}>
            <Stack height={"100%"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <Stack height={"100%"} direction={"row"} width={"100%"} gap={"20px"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"}>
                <Stack height={"100%"} direction={"row"} width={"120%"} gap={responsiveState ? "5px" : "15px"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"}>
                  <Stack backgroundColor={"#e9e9e9"} padding={responsiveState ? "0px 10px 5px 10px" : "5px 25px"}>
                    {carcomponentsState
                      .filter((carcomponent) => [2, 3].includes(carcomponent.id))
                      .map((carcomponent) => (
                        <CarcomponentComponent
                          demo={demo}
                          isFirstIntervention={isFirstIntervention}
                          modifiedCarcomponents={modifiedCarcomponents}
                          handleComponentChange={handleComponentChange}
                          key={carcomponent._id}
                          carcomponent={carcomponent}
                          vehicleState={vehicleState}
                        />
                      ))}
                  </Stack>
                  <Stack backgroundColor={"#e9e9e9"} padding={responsiveState ? "0px 10px 5px 10px" : "5px 25px"}>
                    {carcomponentsState
                      .filter((carcomponent) => [10, 11].includes(carcomponent.id))
                      .map((carcomponent) => (
                        <CarcomponentComponent
                          demo={demo}
                          isFirstIntervention={isFirstIntervention}
                          modifiedCarcomponents={modifiedCarcomponents}
                          handleComponentChange={handleComponentChange}
                          key={carcomponent._id}
                          carcomponent={carcomponent}
                          vehicleState={vehicleState}
                        />
                      ))}
                  </Stack>
                  <Stack backgroundColor={"#e9e9e9"} padding={responsiveState ? "0px 10px 5px 10px" : "5px 25px"}>
                    {carcomponentsState
                      .filter((carcomponent) => [12, 13].includes(carcomponent.id))
                      .map((carcomponent) => (
                        <CarcomponentComponent
                          demo={demo}
                          isFirstIntervention={isFirstIntervention}
                          modifiedCarcomponents={modifiedCarcomponents}
                          handleComponentChange={handleComponentChange}
                          key={carcomponent._id}
                          carcomponent={carcomponent}
                          vehicleState={vehicleState}
                        />
                      ))}
                  </Stack>
                  <Stack height={"100%"} direction={"row"} width={"100%"} gap={"20px"} flexWrap={"wrap"} alignItems={"center"} paddingBottom={"70px"} justifyContent={"center"}>
                    {carcomponentsState
                      .filter((carcomponent) => filterIds.suspension.includes(carcomponent.id))
                      .map((carcomponent) => (
                        <CarcomponentComponent
                          demo={demo}
                          isFirstIntervention={isFirstIntervention}
                          modifiedCarcomponents={modifiedCarcomponents}
                          handleComponentChange={handleComponentChange}
                          key={carcomponent._id}
                          carcomponent={carcomponent}
                          vehicleState={vehicleState}
                        />
                      ))}
                  </Stack>
                </Stack>
              </Stack>

              {!responsiveState && (
                <Stack height={"calc(100vh - 100px)"} width={"20%"}>
                  <img style={{ right: "0", margin: "auto", zIndex: "-20", width: "100%" }} src="/img/suspension_illustration.webp"></img>
                </Stack>
              )}
            </Stack>
          </motion.div>
        )}
        {filterValue === "other" && (
          <motion.div style={{ width: "100%" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "spring", stiffness: 500, damping: 100 }}>
            <Stack height={"100%"} direction={"row"} alignItems={"flex-start"} justifyContent={"center"}>
              <Stack height={"100%"} direction={"row"} width={"100%"} gap={"20px"} flexWrap={"wrap"} alignItems={"flex-start"} justifyContent={"center"}>
                {carcomponentsState
                  .filter((carcomponent) => filterIds.other.includes(carcomponent.id))
                  .map((carcomponent) => (
                    <CarcomponentComponent
                      demo={demo}
                      isFirstIntervention={isFirstIntervention}
                      modifiedCarcomponents={modifiedCarcomponents}
                      handleComponentChange={handleComponentChange}
                      key={carcomponent._id}
                      carcomponent={carcomponent}
                      vehicleState={vehicleState}
                    />
                  ))}
              </Stack>
            </Stack>
          </motion.div>
        )}
        {disableConfirmIntervention() && <Tooltip open={true} style={{ position: "fixed", bottom: "110px", right: "170px" }} title="Asegurate de que todos los datos estén intruducidos"></Tooltip>}
        <Button
          onClick={() => setConfirmModal(true)}
          disabled={disableConfirmIntervention()}
          sx={{
            width: "fit-content",
            lineHeight: "20px",
            borderRadius: "50px",
            paddingLeft: "20px",
            textAlign: "left",
            position: "fixed",
            bottom: "15px",
            right: "30px",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          }}
          variant="contained"
        >
          Confirmar <br /> Intervención <ArrowCircleRightIcon sx={{ marginLeft: "12px" }} />
        </Button>
      </Stack>
    </>
  );
};

export default NavigatorComponent;
