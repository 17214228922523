import { FormControlLabel, IconButton, Radio, RadioGroup, Slider, Stack, TextField, Typography } from "@mui/material";
import React, { useState, memo, useCallback } from "react";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import CarcomponentConfigModal from "./CarcomponentConfig.Modal";

const CarcomponentComponent = memo(
  ({ demo, vehicle, isConfirmed, carcomponent, handleComponentChange, modifiedCarcomponents, isFirstIntervention, vehicleState }) => {
    const modifiedCarcomponent = modifiedCarcomponents.find((item) => item.id === carcomponent.id) || {};

    const initialStatus = modifiedCarcomponent.status !== undefined ? modifiedCarcomponent.status : 3;
    const initialIsReplaced = modifiedCarcomponent.isReplaced !== undefined ? modifiedCarcomponent.isReplaced : false;
    const initialCaducidadKm = modifiedCarcomponent.caducidadKm || carcomponent.caducidadKm;
    const initialCaducidadMeses = modifiedCarcomponent.caducidadMeses || carcomponent.caducidadMeses;
    const initialSliderValue = modifiedCarcomponent.percentage !== undefined ? modifiedCarcomponent.percentage : 100;
    const initialType = modifiedCarcomponent.type !== undefined ? modifiedCarcomponent.type : "";

    const [carcomponentStatus, setCarcomponentStatus] = useState(initialStatus);
    const [isReplaced, setIsReplaced] = useState(initialIsReplaced);
    const [caducidadKm, setCaducidadKm] = useState(initialCaducidadKm);
    const [caducidadMeses, setCaducidadMeses] = useState(initialCaducidadMeses);
    const [isPercentage, setIsPercentage] = useState(carcomponent.name.includes("Neumáticos") || carcomponent.name.includes("Pastillas"));
    const [sliderValue, setSliderValue] = useState(initialSliderValue);

    const [carcomponentType, setCarcomponentType] = useState(initialType);

    const [configurationOpen, setConfigurationOpen] = useState(false);

    const [matchRearToFrontTires, setmatchRearToFrontTires] = useState(carcomponent.id === 3 ? true : null);

    const radio_colors_style = [
      { key: 0, value: "#4abe48" },
      { key: 1, value: "#fd8d03" },
      { key: 2, value: "#e73d3d" },
      { key: 3, value: "#bdbdbd" },
    ];

    const sliderStyles = {
      transition: "all 0.2s",
      color: carcomponentStatus === 3 ? "#bdbdbd" : sliderValue > 61 ? "#4abe48" : sliderValue > 29 ? "#fd8d03" : "#e73d3d",
    };

    const radioStyle = (_value, _isSelected) => {
      return {
        display: "flex",
        transition: "all 0.2s",
        flexWrap: "wrap",
        justifyContent: "center",
        background: "red",
        width: "55px",
        margin: "0!important",
        padding: "3px 6px",
        background: _isSelected && _value < 3 ? radio_colors_style[_value]?.value : "",
        color: _isSelected && _value < 3 ? "#ffffff" : "#333",
        "& .Mui-checked": {
          color: _isSelected && _value < 3 ? "#ffffff!important" : "#333",
        },
        "& span": {
          fontSize: "0.75rem",
        },
      };
    };

    const getAriaValueText = (value) => {
      return `${value}%`;
    };

    const handleStatusChange = (event) => {
      setCarcomponentStatus(parseInt(event.target.value));
      handleComponentChange({ ...carcomponent, status: parseInt(event.target.value) });
    };

    const handleType = (event) => {
      setCarcomponentType(event.target.value);
      handleComponentChange({
        ...carcomponent,
        isReplaced,
        status: carcomponentStatus,
        percentage: sliderValue,
        type: event.target.value,
      });
    };

    const closeModal = useCallback(() => setConfigurationOpen(false), []);

    const handleIsReplaced = () => {
      setIsReplaced(!isReplaced);
      if (isPercentage) {
        if (!isReplaced) {
          handleComponentChange({ ...carcomponent, status: 0, isReplaced: !isReplaced, percentage: 100 });
        } else {
          handleComponentChange({ ...carcomponent, status: carcomponentStatus, isReplaced: false, percentage: sliderValue });
        }
      } else {
        setCarcomponentStatus(0);
        handleComponentChange({ ...carcomponent, status: 0, isReplaced: !isReplaced });
      }
    };

    const handleSliderValue = (event, newValue) => {
      setSliderValue(newValue);

      if (newValue > 61) {
        setCarcomponentStatus(0);
        handleComponentChange({ ...carcomponent, status: 0, percentage: newValue });
      } else if (newValue > 29) {
        setCarcomponentStatus(1);
        handleComponentChange({ ...carcomponent, status: 1, percentage: newValue });
      } else {
        setCarcomponentStatus(2);
        handleComponentChange({ ...carcomponent, status: 2, percentage: newValue });
      }
    };

    return (
      <Stack
        sx={{
          transition: "0.2s",
          border: isReplaced ? "3px solid #4abe48" : "3px solid transparent",
          marginBottom: "20px",
          position: "relative",
          width: "270px",
          padding: "5px",
          paddingTop: "15px",
          gap: "10px",
          paddingBottom: "40px",
          boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.25)",
          marginTop: "20px",
          height: "90px",
          backgroundColor: "white",
          pointerEvents: isConfirmed ? "none" : "auto",
        }}
      >
        <Typography sx={{ fontWeight: "300", fontSize: "1rem", textAlign: "center" }}>{carcomponent.name}</Typography>

        {(carcomponent.id === 1 || carcomponent.id === 2 || carcomponent.id === 3 || (carcomponent.id === 19 && demo) || (carcomponent.id === 18 && demo) || (carcomponent.id === 23 && demo)) && (
          <IconButton onClick={() => setConfigurationOpen(!configurationOpen)} sx={{ transition: "all 0.2s", position: "absolute", top: "5px", right: "5px", color: "#333", "&:hover": { color: "#4abe48" } }}>
            <SettingsIcon sx={{ color: "grey" }} />
          </IconButton>
        )}

        {configurationOpen && (
          <CarcomponentConfigModal
            modifiedCarcomponents={modifiedCarcomponents}
            handleType={handleType}
            carcomponentType={carcomponentType}
            carcomponent={carcomponent}
            configurationOpen={configurationOpen}
            setConfigurationOpen={setConfigurationOpen}
            closeModal={closeModal}
            setCarcomponentType={setCarcomponentType}
          />
        )}

        {!isReplaced && !isPercentage && (
          <RadioGroup onChange={(e) => handleStatusChange(e)} sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }} value={carcomponentStatus} defaultValue="0">
            <FormControlLabel sx={radioStyle(0, carcomponentStatus === 0)} labelPlacement="bottom" value={0} control={<Radio />} label={"Óptimo"} />
            <FormControlLabel sx={radioStyle(1, carcomponentStatus === 1)} labelPlacement="bottom" value={1} control={<Radio />} label={"Regular"} />
            <FormControlLabel sx={radioStyle(2, carcomponentStatus === 2)} labelPlacement="bottom" value={2} control={<Radio />} label={"Deficiente"} />
            <FormControlLabel sx={radioStyle(3, carcomponentStatus === 3)} labelPlacement="bottom" value={3} control={<Radio />} label={"No rev"} />
          </RadioGroup>
        )}
        {isPercentage && !isReplaced && (
          <Stack direction={"row"} gap={"20px"} sx={{ padding: "10px", alignItems: "center" }}>
            <Typography
              sx={{
                width: "20%",
                textAlign: "center",
                transition: "all 0.2s",
                minWidth: "45px",
                backgroundColor: carcomponentStatus === 3 ? "#bdbdbd" : sliderValue > 61 ? "#4abe48" : sliderValue > 29 ? "#fd8d03" : "#e73d3d",
                padding: "5px 10px",
                borderRadius: "50px",
                color: "white",
              }}
            >
              <strong>{carcomponentStatus === 3 ? "?" : `${sliderValue}%`}</strong>
            </Typography>
            <Slider
              sx={sliderStyles}
              onChange={handleSliderValue}
              value={sliderValue}
              aria-label="Percentage"
              color="#4abe48"
              defaultValue={100}
              getAriaValueText={getAriaValueText}
              valueLabelDisplay="auto"
              shiftStep={30}
              step={10}
              marks
              min={0}
              max={100}
              width={"100%"}
            />
            <RadioGroup
              onChange={(e) => handleStatusChange(e)}
              sx={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}
              value={carcomponentStatus}
              defaultValue="0"
            >
              <FormControlLabel sx={radioStyle(3, carcomponentStatus === 3)} labelPlacement="bottom" value={3} control={<Radio />} label={"No rev"} />
            </RadioGroup>
          </Stack>
        )}
        {isReplaced && (
          <Stack direction={"row"} gap={"10px"} padding={"10px"}>
            <TextField
              InputProps={{
                style: {
                  borderRadius: "0px",
                },
              }}
              value={caducidadKm}
              onChange={(e) => setCaducidadKm(e.target.value)}
              label="Caducidad Km"
              type="number"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  setCaducidadKm((prev) => prev + (e.shiftKey ? 1000 : 100));
                  e.preventDefault();
                } else if (e.key === "ArrowDown") {
                  setCaducidadKm((prev) => Math.max(0, prev - (e.shiftKey ? 1000 : 100)));
                  e.preventDefault();
                }
              }}
              sx={{
                width: "50%",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4abe48",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#4abe48",
                },
              }}
            />
            <TextField
              InputProps={{
                style: {
                  borderRadius: "0px",
                },
              }}
              value={caducidadMeses}
              onChange={(e) => setCaducidadMeses(e.target.value)}
              label="Caducidad Meses"
              type="number"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  setCaducidadMeses((prev) => prev + (e.shiftKey ? 10 : 1));
                  e.preventDefault();
                } else if (e.key === "ArrowDown") {
                  setCaducidadMeses((prev) => Math.max(0, prev - (e.shiftKey ? 10 : 1)));
                  e.preventDefault();
                }
              }}
              sx={{
                width: "50%",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4abe48",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#4abe48",
                },
              }}
            />
          </Stack>
        )}
        <Stack
          onClick={handleIsReplaced}
          sx={{
            backgroundColor: isReplaced ? "#d7ffd6" : "white",
            direction: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            position: "absolute",
            left: "0",
            right: "0",
            bottom: "-20px",
            margin: "auto",
            borderRadius: "500px",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
            paddingLeft: "15px",
            border: "2px solid",
            borderColor: isReplaced ? "#4abe48" : "white",
            userSelect: "none",
            cursor: "pointer",
            transition: "all 0.2s",
            "&:hover": { transform: "scale(1.05)" },
          }}
          direction={"row"}
          gap={"10px"}
        >
          <Typography sx={{ fontWeight: "300", fontSize: "0.9rem" }}>{isReplaced ? "Reemplazado" : "No reemplazado"}</Typography>
          <IconButton sx={{ padding: "0px" }}>
            <ChangeCircleIcon
              sx={{
                transition: "all 0.5s",
                color: isReplaced ? "#4abe48" : "secondary.main",
                rotate: isReplaced ? "360deg" : "0deg",
                height: "30px",
                width: "30px",
                backgroundColor: "white",
                borderRadius: "100%",
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.carcomponent === nextProps.carcomponent && prevProps.modifiedCarcomponents === nextProps.modifiedCarcomponents;
  }
);

export default CarcomponentComponent;
