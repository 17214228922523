import React from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackButton = ({ route }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (route === -1) {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        window.close();

        setTimeout(() => {
          navigate("/");
        }, 300);
      }
    } else {
      navigate(route);
    }
  };

  return (
    <IconButton sx={{ zIndex: 3000 }} onClick={handleClick}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
