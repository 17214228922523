import React, { useState, useEffect } from "react";
import { Inject, ScheduleComponent, ViewDirective, ViewsDirective, Day, Week, Month, Agenda } from "@syncfusion/ej2-react-schedule";
import MainContent from "../../components/MainContent.component";
import { registerLicense, loadCldr, L10n } from "@syncfusion/ej2-base";
import { getStorageObject } from "../../utils/LocalStorage.js";
import { Stack } from "@mui/material";
import "./Scheduler.Styles.css";
import * as numberingSystems from "./culture-files/numberingSystems.json";
import * as gregorian from "./culture-files/ca-gregorian.json";
import * as numbers from "./culture-files/numbers.json";
import * as timeZoneNames from "./culture-files/timeZoneNames.json";
import { deleteAppointment, getByRepairshopIdAppointmentsrateResponse, patchAppointment, postAppointment } from "../../services/API/Schedule.Services.js";
import LoadingComponent from "../../components/Loading.Component.jsx";
import { getByRepairshopIdUserMechanics } from "../../services/API/User.Services.js";
import { useAtom } from "jotai";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { getAllByRepairshopIdVehicles } from "../../services/API/Vehicle.Services.js";

registerLicense("Ngo9BigBOggjHTQxAR8/V1NDaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFiW39dcH1XT2BZVUdxVg==");

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

L10n.load({
  es: {
    schedule: {
      day: "Día",
      week: "Semana",
      workWeek: "Semana de trabajo",
      month: "Mes",
      year: "Año",
      agenda: "Agenda",
      weekAgenda: "Agenda de la semana",
      workWeekAgenda: "Agenda de la semana laboral",
      monthAgenda: "Agenda del mes",
      today: "Hoy",
      noEvents: "No hay eventos",
      emptyContainer: "No hay eventos programados para este día.",
      allDay: "Todo el dia",
      start: "comienzo",
      end: "Final",
      more: "más",
      close: "Cerca",
      cancel: "Cancelar",
      noTitle: "(Sin título)",
      delete: "Eliminar",
      deleteEvent: "Este evento",
      deleteMultipleEvent: "Eliminar múltiples eventos",
      selectedItems: "Artículos seleccionados",
      deleteSeries: "Serie completa",
      edit: "Editar",
      editSeries: "Serie completa",
      editEvent: "Este evento",
      createEvent: "Crear",
      subject: "Tema",
      addTitle: "Añadir título",
      moreDetails: "Más detalles",
      save: "Guardar",
      editContent: "¿Cómo le gustaría cambiar la cita en la serie?",
      deleteContent: "¿Seguro que quieres eliminar este evento?",
      deleteMultipleContent: "¿Estás seguro de que deseas eliminar los eventos seleccionados?",
      newEvent: "Nuevo evento",
      title: "Título",
      location: "Ubicación",
      description: "Descripción",
      timezone: "Zona horaria",
      startTimezone: "Zona horaria de inicio",
      endTimezone: "Zona horaria final",
      repeat: "Repetir",
      saveButton: "Guardar",
      cancelButton: "Cancelar",
      deleteButton: "Eliminar",
      recurrence: "Reaparición",
      wrongPattern: "El patrón de recurrencia no es válido.",
      seriesChangeAlert: "¿Desea cancelar los cambios realizados en instancias específicas de esta serie y volver a vincularlos con toda la serie?",
      createError: "La duración del evento debe ser más corta que la frecuencia con la que ocurre. Acorte la duración o cambie el patrón de recurrencia en el editor de eventos de recurrencia.",
      sameDayAlert: "Dos ocurrencias del mismo evento no pueden ocurrir en el mismo día.",
      editRecurrence: "Editar recurrencia",
      repeats: "Repite",
      alert: "Alerta",
      startEndError: "La fecha de finalización seleccionada ocurre antes de la fecha de inicio.",
      invalidDateError: "El valor de la fecha ingresada no es válido.",
      blockAlert: "Los eventos no se pueden programar dentro del rango de tiempo bloqueado.",
      ok: "Okay",
      yes: "si",
      no: "No",
      occurrence: "Ocurrencia",
      series: "Serie",
      previous: "Anterior",
      next: "próximo",
      timelineDay: "Día de la línea de tiempo",
      timelineWeek: "Semana de la línea de tiempo",
      timelineWorkWeek: "Semana laboral cronológica",
      timelineMonth: "Mes de la línea de tiempo",
      timelineYear: "Cronología Año",
      editFollowingEvent: "Eventos siguientes",
      deleteTitle: "Eliminar evento",
      editTitle: "Editar evento",
      beginFrom: "Comience desde",
      endAt: "Termina en",
      expandAllDaySection: "Expandir-sección-todo-el-día",
      collapseAllDaySection: "Colapsar la sección de todo el día",
    },
    recurrenceeditor: {
      none: "Ninguna",
      daily: "Diario",
      weekly: "Semanal",
      monthly: "Mensual",
      month: "Mes",
      yearly: "Anual",
      never: "Nunca",
      until: "Hasta",
      count: "Contar",
      first: "primero",
      second: "Segundo",
      third: "Tercero",
      fourth: "Cuarto",
      last: "Último",
      repeat: "Repetir",
      repeatEvery: "Repite cada",
      on: "Repetir en",
      end: "Final",
      onDay: "Día",
      days: "Dias)",
      weeks: "Semanas)",
      months: "Meses)",
      years: "Años)",
      every: "cada",
      summaryTimes: "veces)",
      summaryOn: "en",
      summaryUntil: "hasta",
      summaryRepeat: "Repite",
      summaryDay: "dias)",
      summaryWeek: "semanas)",
      summaryMonth: "meses)",
      summaryYear: "años)",
      monthWeek: "Mes Semana",
      monthPosition: "Posición del mes",
      monthExpander: "Expansor de mes",
      yearExpander: "Expansor de año",
      repeatInterval: "Intervalo de repetición",
    },
    calendar: {
      today: "Hoy",
    },
  },
});

export const editorTemplate = (mechanics, vehicles, props) => {
  useEffect(() => {
    console.log("MECHS", mechanics);
    console.log("PROPS", props);
    console.log("Vehicles", vehicles);
    console.log("Mechanics type:", typeof mechanics);
  }, []);

  const mechanicsList = Array.isArray(mechanics)
    ? mechanics.map((mechanic) => ({
        Id: mechanic.id,
        Mechanic: `${mechanic.name_first} ${mechanic.name_last || ""}`,
      }))
    : [];

  const onFiltering = (args, dataSource, textField) => {
    console.log("FILTRASIANDO");
    const query = args.text.toLowerCase();
    args.updateData(dataSource.filter((item) => item[textField].toLowerCase().includes(query)));
  };

  const capitalize = (str) => (str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "");

  // Updated vehiclesList logic
  const vehiclesList = Array.isArray(vehicles)
    ? vehicles.map((vehicle) => ({
        Id: vehicle.id,
        Vehicle: `${capitalize(vehicle.vehicle_manufacturer?.name || "")} ${vehicle.vehicle_model?.name || ""} ${vehicle.plate || ""}`,
      }))
    : [];

  return props !== undefined ? (
    <div className="custom-event-container">
      <table className="custom-event-editor">
        <tbody>
          {/* Nombre del evento */}
          <tr>
            <td className="e-textlabel">Nombre del evento</td>
            <td colSpan={4}>
              <input id="Summary" className="e-field e-input" type="text" name="Subject" style={{ width: "100%" }} />
            </td>
          </tr>

          {/* Asignar mecánico */}
          <tr>
            <td className="e-textlabel">Asignar mecánico</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="MechanicSelector"
                placeholder="Escoge el mecánico"
                data-name="Mechanic"
                className="e-field"
                dataSource={mechanicsList}
                fields={{ text: "Mechanic", value: "Id" }}
                value={props.Mechanic || null}
                style={{ width: "100%" }}
              />
            </td>
          </tr>

          {/* Asociar vehículo */}
          <tr>
            <td className="e-textlabel">Asociar vehículo</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="VehicleSelector"
                placeholder="Selecciona un vehículo"
                data-name="Vehicle"
                className="e-field"
                dataSource={vehiclesList}
                fields={{ text: "Vehicle", value: "Id" }}
                allowFiltering={true}
                filtering={(args) => onFiltering(args, vehiclesList, "Vehicle")}
                filterBarPlaceholder="Buscar vehículo"
                value={props.Vehicle || null}
                style={{ width: "100%" }}
              />
            </td>
          </tr>

          {/* Desde */}
          <tr>
            <td className="e-textlabel">Desde</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                locale="es"
                format="dd/MM/yy hh:mm a"
                id="StartTime"
                data-name="StartTime"
                value={new Date(props.startTime || props.StartTime)}
                className="e-field"
                style={{ width: "100%" }}
              />
            </td>
          </tr>

          {/* Hasta */}
          <tr>
            <td className="e-textlabel">Hasta</td>
            <td colSpan={4}>
              <DateTimePickerComponent format="dd/MM/yy hh:mm a" id="EndTime" data-name="EndTime" value={new Date(props.endTime || props.EndTime)} className="e-field" style={{ width: "100%" }} />
            </td>
          </tr>

          {/* Descripción */}
          <tr>
            <td className="e-textlabel">Descripción</td>
            <td colSpan={4}>
              <textarea id="Description" className="e-field e-input" name="Description" rows={3} cols={50} style={{ resize: "none", width: "100%" }}></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div></div>
  );
};

const SchedulerPage = () => {
  const [scheduleData, setScheduleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupEventData, setPopupEventData] = useState(null);
  const [mechanics, setMechanics] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    const token = getStorageObject("token");
    const repairshopId = getStorageObject("repairShopId");

    getByRepairshopIdAppointmentsrateResponse(token, repairshopId).then((response) => {
      getByRepairshopIdUserMechanics(token, repairshopId).then((mechanics) => {
        getAllByRepairshopIdVehicles(token, repairshopId).then((vehicles) => {
          setVehicles(vehicles.data);
        });
        setMechanics(mechanics.data);
        if (response?.data?.length > 0) {
          setScheduleData(response.data);
        }
        setLoading(false);
      });
    });
  }, []);

  const handlePopupSave = (eventData) => {
    if (eventData.Id) {
      // Update existing event
      const updatedData = scheduleData.map((event) => (event.Id === eventData.Id ? eventData : event));
      setScheduleData(updatedData);
      patchAppointment(getStorageObject("token"), {
        id_user_repairshop: getStorageObject("repairShopId"),
        appointment_data: eventData,
      });
    } else {
      // Create new event
      const newEvent = {
        ...eventData,
        Id: Math.floor(Math.random() * 1_000_000_000),
      };
      setScheduleData([...scheduleData, newEvent]);
      postAppointment(getStorageObject("token"), {
        id_user_repairshop: getStorageObject("repairShopId"),
        appointment_data: newEvent,
      });
    }
  };

  const onActionBegin = async (args) => {
    console.log(args);

    if (args.requestType === "eventCreate") {
      args.cancel = true;
      const newRecord = {
        ...args.addedRecords[0],
        Id: Math.floor(Math.random() * 1_000_000_000),
      };
      const createdAppointment = await postAppointment(getStorageObject("token"), {
        id_user_repairshop: getStorageObject("repairShopId"),
        appointment_data: newRecord,
      });

      console.log("cretaeddd", createdAppointment);

      setScheduleData([...scheduleData, createdAppointment.data]);
    } else if (args.requestType === "eventChange") {
      const updatedData = scheduleData.map((event) => (event.Id === args.changedRecords[0].Id ? args.changedRecords[0] : event));
      await patchAppointment(getStorageObject("token"), {
        id_user_repairshop: getStorageObject("repairShopId"),
        appointment_data: args.changedRecords[0],
      });
      setScheduleData(updatedData);
    } else if (args.requestType === "eventRemove") {
      const filteredData = scheduleData.filter((event) => event.Id !== args.deletedRecords[0].Id);
      await deleteAppointment(getStorageObject("token"), args.deletedRecords[0].Id);
      setScheduleData(filteredData);
    }
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <MainContent>
        <Stack sx={{ marginTop: "40px", width: "100%" }}>
          <ScheduleComponent
            toolbar={["Day", "Week", "Month", "Today", "Agenda"]}
            cssClass="custom-schedule"
            locale="es"
            editorTemplate={editorTemplate.bind(this, mechanics, vehicles)}
            eventSettings={{ dataSource: scheduleData }}
            actionBegin={onActionBegin}
          >
            <ViewsDirective>
              <ViewDirective option="Day" />
              <ViewDirective option="Week" />
              <ViewDirective option="Agenda" />
              <ViewDirective option="Month" />
            </ViewsDirective>
            <Inject services={[Day, Week, Month, Agenda]} />
          </ScheduleComponent>
        </Stack>
      </MainContent>
    </>
  );
};

export default SchedulerPage;
