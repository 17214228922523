import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormControl, InputAdornment, MenuItem, Select, Stack } from '@mui/material';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { migrateRepairshops } from '../../../services/API/User.Services';
import { getStorageObject } from '../../../utils/LocalStorage';
import ModelComponent from './ModelComponent';
import { updateRepairshopSubscription } from '../../../services/API/Admin.Services';
import { useToast } from '../../../components/toastComponent/toast.Actions';


function createData(id, name, email, phone, id_user_repairshop, id_user_profile, id_user_subscription) {
  return {
    id,
    name,
    email,
    phone,
    id_user_repairshop,
    id_user_profile,
    id_user_subscription,
    first_login: false
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function renderUserSubscription(id_user_subscription) {

  switch (id_user_subscription) {
    case 1:
      return 'Pro';
    case 2:
      return 'Turbo';
    case 3:
      return 'Gratis';
    default:
      return '';
  }
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Taller',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Teléfono',
  },
  {
    id: 'subscription',
    numeric: false,
    disablePadding: false,
    label: 'Suscripción',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: 'primary.main' }}>
      <TableRow>

        <TableCell padding="checkbox">

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell

            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ color: 'white', fontWeight: '200', fontSize: '1.2rem' }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}



EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CustomTableRow({ handleWorkshopClick, row, vehicles, setInterventionData, loadingState, setLoadingState }) {

  const {openCustomToast} = useToast()

  const handleUpdateRepairshopSubscription = async (repairshop, id_user_subscription) => {
    const token = getStorageObject("adminSessionToken")
    const id_user_main = repairshop.id
    const response = await updateRepairshopSubscription(token, { id_user_main, id_user_subscription })

    if (response?.status === 200) {
      openCustomToast("Suscripción actualizada", "success", true);
    } else {
      openCustomToast("No se ha podido actualizar la suscripción", "error");
    }
  }

  const [open, setOpen] = useState(false);
  const openBox = () => {
    setOpen(!open)
  }

 // const navigate = useNavigate()


  return (
    <>
      <TableRow
        onClick={() => {
          handleWorkshopClick(row);
        }}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        sx={{ cursor: "pointer" }}
      >
        <TableCell padding="checkbox"></TableCell>
        <TableCell component="th" scope="row" padding="none">
          {row.name}
        </TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{row.phone}</TableCell>
        <TableCell onClick={(e)=>e.stopPropagation()} align="left">
          <FormControl onClick={(e)=>e.stopPropagation()} fullWidth>
            <Select sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}}  InputLabelProps={{shrink: false}} onClick={(e)=>e.stopPropagation()} id="subscription" defaultValue={row.id_user_subscription} label="Subscription" onChange={(e)=>{
              handleUpdateRepairshopSubscription(row, e.target.value)
            }} >
              <MenuItem onClick={(e)=>e.stopPropagation()} value={3}>Gratis</MenuItem>
              <MenuItem onClick={(e)=>e.stopPropagation()} value={2}>Turbo</MenuItem>
              <MenuItem onClick={(e)=>e.stopPropagation()} value={1}>Pro</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>

      <TableRow sx={{ cursor: "pointer", marginLeft: "25px", padding: "0" }}>
        <TableCell style={{ backgroundColor: "#f1f1f1", paddingBottom: 0, paddingTop: 0, boxShadow: "inset 0px 20px 30px -20px rgba(0,0,0,0.1)" }} colSpan={6}></TableCell>
      </TableRow>
    </>
  );
}

export default function DataTable({ handleWorkshopClick, repairshops, setRepairshopData, searchValue, setSearchValue, demo, setLoadingState, loadingState }) {
  const rows = repairshops.map((repairshop) => {

    return createData(
      repairshop.id_user_main,
      repairshop.name,
      repairshop.email,
      repairshop.phone_number,
      repairshop.id,
      repairshop.user_main.id_user_profile,
      repairshop.user_main.id_user_subscription
    );
  });
  const rowHeight = 93

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('confirmed');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(Math.floor(window.innerHeight / rowHeight) - 3);


  useEffect(() => {
    const handleResize = () => {
      setRowsPerPage(Math.floor(window.innerHeight / rowHeight) - 3);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const navigate = useNavigate()
  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleMigration = async () => {

    const token = getStorageObject("token");

    const reponse = await migrateRepairshops(token);

    return reponse
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows =

    stableSort(rows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )

  const [vehicleModalIsOpen, setVehicleModalIsOpen] = useState(false)


  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Paper sx={{ width: "100%", mb: 2, boxShadow: "0 0 20px 0 rgba(0,0,0,0.14)" }}>
        <TableContainer>
          <Stack sx={{ flexDirection: "row", paddingRight: "20px", paddingLeft: "20px", justifyContent: "space-between", alignItems: "center", backgroundColor: "primary.main" }}>
            {demo === false && (
              <Button
                onClick={() => navigate("/intervencion/crear")}
                sx={{
                  color: "white",
                  borderBottom: "1px solid white",
                  paddingBottom: "0px",
                  textTransform: "none",
                  fontWeight: 200,
                  fontSize: "1.1rem",
                  "&:hover": { borderBottom: "2px solid white", marginBottom: "-1px" },
                }}
              >
                + Crear intervención
              </Button>
            )}
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              sx={{
                paddingTop: "10px",
                width: "30%",
                color: "white",
                "& input": {
                  color: "white",
                },
                "& input::placeholder": {
                  color: "white",
                  opacity: 1,
                  fontWeight: "200",
                },
                "& .MuiInput-underline::before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline.Mui-focused::before": {
                  borderBottomColor: "white",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "white" }} />
                  </InputAdornment>
                ),
              }}
              id="outlined-basic"
              placeholder="Buscar"
              variant="standard"
            />
                    <Button onClick={handleMigration} sx={{ backgroundColor: "white", color: "primary.main", padding: "0px 10px", '&:hover': { backgroundColor: "primary.main", color: "white" } }} variant="contained">Migrar talleres</Button>
                    <Button onClick={()=>{setVehicleModalIsOpen(true)}} sx={{ backgroundColor: "white", color: "primary.main", padding: "0px 10px", '&:hover': { backgroundColor: "primary.main", color: "white" } }} variant="contained">Administrar modelos y marcas</Button>

          </Stack>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={rows.length} />

            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                const open = false;

                const openBox = () => {
                  open = !open;
                };

                return <CustomTableRow handleWorkshopClick={handleWorkshopClick} key={row.id} row={row} vehicles={repairshops} setLoadingState={setLoadingState} loadingState={loadingState} />;
              })}
              {emptyRows > 0 && (
                <>
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
       {vehicleModalIsOpen && <ModelComponent open={vehicleModalIsOpen} setOpen={setVehicleModalIsOpen} />}
        <TablePagination rowsPerPageOptions={[]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </Box>
  );
}