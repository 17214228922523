import { ApiCall_GET, ApiCall_POST, ApiCall_PATCH } from "..";

export const APILogin = async (_email, _password) => {
  const response = await ApiCall_POST("/api/login", { email: _email, password: _password });
  return response;
}

export const ValidateToken = async (_token) => {

  if (_token) {
    const response = await ApiCall_GET("/api/verificatoken", _token);
    const parsed = response;
    return parsed;
  }
}

export const ChangePassword = async (_token, _password, _id, _oldPassword) => {

  try {
    const response = await ApiCall_POST("/api/updatePassword", { id: _id, password: _password, oldPassword: _oldPassword || null }, _token);
    const parsed = response;
    return parsed;
  } catch (error) {
    console.error(error);
  }
}

export const recoveryPasswordUpdate = async (_token, _password, _id) => {

  try {
    const response = await ApiCall_PATCH("/api/recoverypasswordupdate", { id: _id, password: _password, token: _token });
    const parsed = response;
    return parsed;
  } catch (error) {
    console.error(error);
  }
}