import {
  ApiCall_GET,
  ApiCall_PATCH,
  ApiCall_POST,
  ApiCall_POST_Files,
} from "..";

export const getByRepairshopIdUserMechanics = async (_token) => {
  const response = await ApiCall_GET(
    `/api/getbyrepairshopidusermechanics`,
    _token,
  );
  return response;
};

export const createUserMechanic = async (_token, _body) => {
  const response = await ApiCall_POST(`/api/postusermechanic`, _body, _token);
  return response;
};

export const updateUserMechanic = async (_token, _body) => {
  const response = await ApiCall_PATCH(
    `/api/updateusermechanic`,
    _body,
    _token,
  );
  return response;
};

export const deleteUserMechanic = async (_token, _body) => {
  console.log(_body);
  const response = await ApiCall_PATCH(
    `/api/deleteusermechanic`,
    { id: _body },
    _token,
  );
  return response;
};

export const getAllByRepairshopIdUsers = async (_token) => {
  const response = await ApiCall_GET(`/api/getAllByRepairshopIdUsers`, _token);
  return response;
};

export const getByIdRepairshop = async (_token) => {
  const response = await ApiCall_GET(`/api/getbyidrepairshop`, _token);
  return response;
};

export const updateRepairshopDetails = async (_token, _body) => {
  const response = await ApiCall_PATCH(
    `/api/updaterepairshopdetails`,
    _body,
    _token,
  );
  return response;
};

export const getCalendarWorkloadData = async (_token) => {
  const response = await ApiCall_GET(
    `/api/getByRepairshopIdCalendarWorkload`,
    _token,
  );
  return response;
};

export const postCalendarWorkloadData = async (_token, _body) => {
  const response = await ApiCall_POST(
    `/api/postcalendarworkload`,
    _body,
    _token,
  );
  return response;
};

export const migrateRepairshops = async (_token, _body) => {
  const response = await ApiCall_POST(
    `/migration/userRepairShopMigration`,
    _body,
    _token,
  );
  return response;
};

export const deleteCalendarWorkload = async (_token) => {
  const response = await ApiCall_POST(
    `/api/deleteCalendarWorkload?`,
    {},
    _token,
  );
  return response;
};

export const recoverAccount = async (_email) => {
  const response = await ApiCall_POST(`/api/recoveraccount`, { email: _email });
  return response;
};

export const validateRecoveryLink = async (_token, _id) => {
  const response = await ApiCall_GET(
    `/api/validateRecoveryLink?id=${_id}&token=${_token}`,
    _token,
  );
  return response;
};

export const getAllRepairshops = async (_token) => {
  const response = await ApiCall_GET(`/api/admin/getAllRepairshops`, _token);
  return response;
};

export const uploadProfilePicture = async (_token, _id, _file) => {
  let formData = new FormData();
  formData.append("file", _file);

  const response = await ApiCall_POST_Files(
    `/api/uploadprofilepicture?id=${_id}`,
    formData,
    _token,
  );

  return response;
};

export const uploadDatabase = async (_token, _id, _file) => {
  let formData = new FormData();
  formData.append("file", _file);

  const response = await ApiCall_POST_Files(
    `/fire/postdatabase?id=${_id}`,
    formData,
    _token,
  );

  return response;
};
