import { ApiCall_POST, ApiCall_GET } from "..";

export const postLead = async (_leadData, _leadUuid) => {
    try {
        const response = await ApiCall_POST(`/api/postlead?uuid=${_leadUuid}`, _leadData);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export const getbyUuidLead = async (_leadUuid) => {
    try {
        const response = await ApiCall_GET(`/api/getbyuuidlead?uuid=${_leadUuid}`);

        if (!response) { return false; }

        const parsed = response;

        if (parsed) {
            if (parsed.last_name) {
                return 6;
            } else if (parsed.phone) {
                return 4;
            } else if (parsed.workers) {
                return 3;
            } else if (parsed.province) {
                return 2;
            } else if (parsed.name_workshop) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return false;
        }

    } catch (error) {
        console.error(error);
    }
}