export const getStorageObject = (_key) => {
    return JSON.parse(localStorage.getItem(_key));
}

export const setStorageObject = (_key, _value) => {
    localStorage.setItem(_key, JSON.stringify(_value));
}

export const deleteStorageObject = (_key) => {
    localStorage.removeItem(_key);
}   